import { Settings } from '@mui/icons-material'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import theme from 'theme/designTokens'
import {
  Sectors,
  Declarations,
  DocumentTypes,
  SectorsMutate,
  ContractsTypes,
  EditDeclaretion,
  CreateDeclaretion,
  EditContractsTypes,
  CreateContractsTypes,
  LogUsers,
  SearchUnifyEnrollment,
  Imports
} from 'components/pages/Admin'
import isAllowUserAccess from '../helpers/isAllowUserAccess'

export const iconProps = {
  color: theme.palette.primary.white
}

const SYSTEM_ROUTES = [
  {
    exact: true,
    icon: <Settings sx={{ ...iconProps }} />,
    label: 'Sistema',
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SYSTEM_DOCUMENT_TYPES'),
    submenu: [
      {
        component: DocumentTypes,
        exact: true,
        label: 'Tipos de Documentos',
        path: ROUTE.documentTypes.list,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SYSTEM_DOCUMENT_TYPES')
      },
      {
        component: SearchUnifyEnrollment,
        exact: true,
        label: 'Unificar matrículas',
        path: ROUTE.documentTypes.unifyEnrollment,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SYSTEM_UNIFY_ENROLLMENT_LIST')
      },
      {
        component: Sectors,
        exact: true,
        label: 'Setores',
        path: ROUTE.sectors.list,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SYSTEM_SECTORS_LIST')
      },
      {
        component: Declarations,
        exact: true,
        label: 'Tipos de Declarações',
        path: ROUTE.LIST_DECLARATIONS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SYSTEM_DECLARETIONS_LIST')
      },
      {
        component: ContractsTypes,
        exact: true,
        label: 'Tipos de Contratos',
        path: ROUTE.contracts.LIST_CONTRACTS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SYSTEM_CONTRACTS_LIST')
      },
      {
        component: LogUsers,
        exact: true,
        label: 'Logs de usuários',
        path: ROUTE.logs.USERS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SYSTEM_LOGS_USERS')
      },
      {
        component: Imports,
        exact: true,
        label: 'Importações',
        path: ROUTE.imports.LIST,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SYSTEM_IMPORTS')
      }
    ]
  },
  {
    component: SectorsMutate,
    exact: true,
    path: ROUTE.sectors.create,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SYSTEM_SECTORS_CREATE')
  },
  {
    component: CreateDeclaretion,
    exact: true,
    path: ROUTE.CREATE_DECLARATIONS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SYSTEM_DECLARATIONS_CREATE')
  },
  {
    component: EditDeclaretion,
    exact: true,
    path: ROUTE.EDIT_DECLARATIONS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SYSTEM_DECLARATIONS_EDIT')
  },
  {
    component: SectorsMutate,
    exact: true,
    path: ROUTE.sectors.edit,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SYSTEM_SECTORS_EDIT')
  },
  {
    component: CreateContractsTypes,
    exact: true,
    path: ROUTE.contracts.CREATE_CONTRACTS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SYSTEM_CONTRACTS_CREATE')
  },
  {
    component: EditContractsTypes,
    exact: true,
    path: ROUTE.contracts.EDIT_CONTRACTS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SYSTEM_CONTRACTS_EDIT')
  }
]

export default SYSTEM_ROUTES
