import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import styled from 'styled-components'

const Text = styled.p``

const Title = styled.h1``

const ErrorOutlineIcon = styled(WarningAmberIcon)`
  font-size: 30px;
`

export { Text, Title, ErrorOutlineIcon }
