import FEEDBACK_SNACK from 'feedBackSnack'
import { ROUTE, SUCCESS, UNITS, yearGradeOldList } from 'services/constants'
import {
  convertToParams,
  downloadXLSXByBuffer,
  formatDate,
  formatDateEnv,
  gradeRuleNotFound,
  isEmpty,
  makeURLAccessLegacy,
  toastNotificationInfo,
  updateErrorMessage
} from 'services/helpers'
import {
  createBatchActionProfessors,
  createDetailsNewSchedules,
  createDetailsProfessors,
  getDetailsBigNumbers,
  getDetailsGradeSheets,
  getDetailsListClassDetails,
  getDetailsListProfessors,
  getDetailsListTimes,
  getDetailsSchoolClassListDisciplines,
  getFrequenciesToPDF,
  getPartialReportToPDF,
  getSchoolReportToPDF,
  getSchoolReportToXLSX,
  getScoreToPDF,
  importIntegrateEvaluation,
  updateDetailsSchoolClass,
  updateDetailsSchoolClassDiscipline
} from 'services/api/admin'
import FrequencyDiaryPDF from 'components/templates/PDF/FrequencyDiary'
import PDF from 'services/helpers/PDF'
import noteDiaryNovotecPDF from 'components/templates/PDF/NoteDiary/Novotec'
import { MATOGROSSO_UNIT, NOVOTEC_UNIT } from 'services/constants/units'
import PartialReportNovotecPDF from 'components/templates/PDF/PartialReport'
import SchoolReportPDF from 'components/templates/PDF/SchoolReport/General'
import { noteDiaryGeneralPDF } from 'components/templates/PDF/NoteDiary/General'
import {
  parseCreateProfessors,
  parseFormValues,
  parseListDisciplines,
  parseNewSchedules,
  parseListGradeSheets,
  parseProfessors,
  parseSelectDisciplines,
  parseStaticFormValues,
  parseTimeDisciplines,
  parseUpdateDisciplines
} from './helpers'
import {
  MESSAGE,
  START_YEAR_FOR_NOVOTEC,
  dateNull,
  defaultItemsPerPage,
  defaultPage,
  getOtherEndpoint,
  idDash,
  idGrades
} from './constants'

const getClassDetails = async ({
  id,
  setSnack,
  setFormValues,
  listCodinator,
  setStaticFormValues,
  setListGradeSheets
}) => {
  const { data, error, status } = await getDetailsListClassDetails(id)

  const params = {
    course_name: data?.course,
    level_name: data?.level
  }

  const {
    data: dataGradeSheets,
    error: errorGradeSheets,
    status: statusGradeSheets
  } = await getDetailsGradeSheets(params)

  if (errorGradeSheets) {
    return updateErrorMessage({
      setSnack,
      error: errorGradeSheets,
      status: statusGradeSheets,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCurriculum
    })
  }

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDetails
    })
  }

  const supervisor = listCodinator?.find(
    (item) => Number(item.value) === Number(data.coordinator_id)
  )

  setListGradeSheets(parseListGradeSheets(dataGradeSheets))
  setStaticFormValues(parseStaticFormValues(data))
  return setFormValues(parseFormValues({ data, supervisor }))
}

const openFile = ({
  typeModal,
  setSnack,
  setLoadingOpen,
  handleClose,
  data
}) => {
  setLoadingOpen(false)

  setSnack(
    typeModal === idGrades
      ? MESSAGE.SUCCESS_GRADES_EXPORT
      : MESSAGE.SUCCESS_RESULT_TRANSCRIPT,
    SUCCESS
  )
  handleClose()

  return makeURLAccessLegacy(data, true)
}

const getExportFiles = async ({
  setSnack,
  setLoadingOpen,
  values,
  id,
  handleClose,
  typeModal
}) => {
  setSnack('')
  setLoadingOpen(true)

  if (typeModal === idGrades) {
    return openFile({
      typeModal,
      setSnack,
      setLoadingOpen,
      handleClose,
      data: `acesso/mapa_notas.php?${convertToParams({
        id_turma: id,
        id_etapa: 4,
        nomeEtapa: 'Módulo',
        ...(values.endDate && { data2: formatDate(values?.endDate) }),
        ...(values.startDate && { data1: formatDate(values?.startDate) }),
        ...(values?.typeFile === 'xls' && { xls: 'true' })
      })}`
    })
  }

  return openFile({
    typeModal,
    setSnack,
    setLoadingOpen,
    handleClose,
    data: `acesso/ata_resultados_TP_15.php?${convertToParams({
      id_turma: id,
      ...(values.endDate && { data2: formatDate(values?.endDate) }),
      ...(values.startDate && { data1: formatDate(values?.startDate) }),
      ...(values?.typeFile === 'xls' && { tipoArquivo: 'excel' })
    })}`
  })
}

const getAtaNovoTec = async ({
  setSnack,
  setLoadingOpen,
  values,
  id,
  handleClose,
  typeModal
}) => {
  setSnack('')
  setLoadingOpen(true)

  return openFile({
    typeModal,
    setSnack,
    setLoadingOpen,
    handleClose,
    data: `acesso/ata_resultados_TP_15_novotec.php?${convertToParams({
      id_turma: id,
      ...(values.endDate && { data2: formatDate(values?.endDate) }),
      ...(values.startDate && { data1: formatDate(values?.startDate) }),
      ...(values?.typeFile === 'xls' && { tipoArquivo: 'excel' })
    })}`
  })
}

const openModalExportPole = ({
  msg,
  row,
  path,
  classId,
  endDate,
  setSnack,
  navigate,
  startDate,
  handleClose,
  setLoadingOpen,
  handleClosePoleDropDown
}) => {
  setLoadingOpen(false)
  if (navigate) {
    navigate(
      `${ROUTE.ADMIN_SECRETARY_CLASSES_DASHBOARD_CLASS}?id_turma=${classId}`,
      {
        state: { savedSearch: true, row, startDate, endDate }
      }
    )
  } else {
    makeURLAccessLegacy(path, true)
  }

  if (msg) setSnack(msg, SUCCESS)

  handleClosePoleDropDown()
  return handleClose()
}

const getExportFilesPole = async ({
  setSnack,
  setLoadingOpen,
  values,
  navigate,
  handleClose,
  typeModalPole,
  classId,
  handleClosePoleDropDown
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { startDate, endDate } = values

  const patAttendanceReport = `acesso/turma_novotec_excel.php?id_turma=${classId}&inicioNovotec=${formatDate(
    startDate || ''
  )}&fimNovotec=${formatDate(endDate) || ''}`

  if (typeModalPole === idDash) {
    return openModalExportPole({
      classId,
      endDate,
      setSnack,
      navigate,
      startDate,
      handleClose,
      setLoadingOpen,
      handleClosePoleDropDown
    })
  }

  return openModalExportPole({
    setLoadingOpen,
    path: patAttendanceReport,
    setSnack,
    handleClose,
    msg: MESSAGE.SUCCESS_ESPORT_ATTENDANCE_REPORT,
    handleClosePoleDropDown
  })
}

const updateSchoolClasses = async ({
  setSnack,
  setLoadingOpen,
  values,
  setFormValues,
  listCodinator,
  setStaticFormValues,
  setListGradeSheets
}) => {
  setSnack('')
  setLoadingOpen(true)

  const payload = {
    name: values?.code,
    lesson_duration: values?.classDuration,
    begin_at: formatDateEnv(values?.startDate),
    end_at: formatDateEnv(values?.endDate),
    grade_year: values?.curriculum,
    min_workload: values?.min_workload || null,
    coordinator_id: values?.supervisor?.value || null,
    ...(values?.qtyClass && { amount_classes: values?.qtyClass }),
    observation: values?.observation || null,
    resolution: values?.resolution || null
  }

  const { error, status } = await updateDetailsSchoolClass(values.id, payload)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateSchoolClasses
    })
  }

  setSnack(MESSAGE.SUCCESS_UPDATE, SUCCESS)

  return getClassDetails({
    id: values.id,
    setSnack,
    listCodinator,
    setFormValues,
    setStaticFormValues,
    setListGradeSheets
  })
}

const getListSCDisciplines = async (
  { id, setSnack, setListDisciplines, setCount, page, filter, setLoadingOpen },
  returnOtherEndpoint
) => {
  if (!returnOtherEndpoint) setSnack('')
  if (setLoadingOpen) setLoadingOpen(true)

  const params = {
    limit: filter || defaultItemsPerPage,
    page: page || defaultPage
  }

  const { data, error, status } = await getDetailsSchoolClassListDisciplines({
    params,
    id
  })

  if (setLoadingOpen) setLoadingOpen(false)

  setCount(data?.count)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplines
    })
  }

  return setListDisciplines(parseListDisciplines(data))
}

const getProfessors = async ({ setSnack, setListProfessors }) => {
  setSnack('')

  const { data, error, status } = await getDetailsListProfessors()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListProfessors
    })
  }

  return setListProfessors(parseProfessors(data))
}

const getSelectSCDisciplines = async ({
  id,
  setSnack,
  setSelectListDisciplines
}) => {
  setSnack('')

  const params = {
    limit: 2000,
    page: 1
  }

  const { data, error, status } = await getDetailsSchoolClassListDisciplines({
    params,
    id
  })

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplines
    })
  }

  return setSelectListDisciplines(parseSelectDisciplines(data))
}

const getListTimeDisiplines = async (
  { id, setSnack, setListSchedules },
  returnOtherEndpoint
) => {
  if (!returnOtherEndpoint) setSnack('')

  const { data, error, status } = await getDetailsListTimes(id)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplinesTimes
    })
  }

  return setListSchedules(parseTimeDisciplines(data))
}

const createSchedules = async ({
  values,
  handleClose,
  setSnack,
  setLoadingOpen,
  classId,
  setListSchedules,
  listDelete,
  setListDelete
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await createDetailsNewSchedules({
    add: parseNewSchedules(values.classSchedule, classId),
    ...(!isEmpty(listDelete) && { destroy: listDelete })
  })

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCreateTimes
    })
  }

  if (!isEmpty(listDelete)) {
    setSnack(MESSAGE.SUCCESS_DELETE_SCHEDULE, SUCCESS)
  }

  setSnack(MESSAGE.SUCCESS_CREATE_SCHEDULES, SUCCESS)
  handleClose()
  setListDelete([])

  return getListTimeDisiplines(
    { id: classId, setSnack, setListSchedules },
    getOtherEndpoint
  )
}

const updateSchoolClassesDisciplines = async ({
  classId,
  setSnack,
  setLoadingOpen,
  values,
  setListDisciplines,
  setCount,
  pageActual,
  filter
}) => {
  setSnack('')
  setLoadingOpen(true)

  const payload = parseUpdateDisciplines(values)?.filter(
    (item) => item.begin_at !== dateNull && item.end_at !== dateNull
  )

  const { error, status } = await updateDetailsSchoolClassDiscipline(
    classId,
    payload
  )

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateDisciplines
    })
  }

  setSnack(MESSAGE.SUCCESS_UPDATE_DISCIPLINES, SUCCESS)

  return getListSCDisciplines(
    {
      id: classId,
      setSnack,
      setListDisciplines,
      setCount,
      pageActual,
      filter
    },
    true
  )
}

const getListBigNumbers = async ({ id, setSnack, setBigNumbers }) => {
  setSnack('')

  const { data, error, status } = await getDetailsBigNumbers(id)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListBigNumber
    })
  }

  return setBigNumbers(data)
}

const getDetails = async ({
  id,
  setSnack,
  setLoadingOpen,
  setFormValues,
  setStaticFormValues,
  listCodinator,
  setListDisciplines,
  setCount,
  pageActual,
  filter,
  setBigNumbers,
  setSelectListDisciplines,
  setListGradeSheets,
  setListSchedules
}) => {
  setSnack('')
  setLoadingOpen(true)

  await getClassDetails({
    id,
    setFormValues,
    setStaticFormValues,
    setListGradeSheets,
    listCodinator,
    noLoading: false
  })

  await getSelectSCDisciplines({
    id,
    setSnack,
    setSelectListDisciplines
  })

  await getListSCDisciplines({
    id,
    setSnack,
    setListDisciplines,
    setCount,
    pageActual,
    filter
  })
  await getListBigNumbers({
    id,
    setSnack,
    setBigNumbers
  })

  await getListTimeDisiplines({
    id,
    setSnack,
    setListSchedules
  })

  return setLoadingOpen(false)
}

const handleCreateProfessors = async ({
  values,
  selectedItem,
  isBatchAction,
  setSnack,
  setLoadingOpen,
  handleClose,
  setSelected,
  id,
  idDisc,
  setListDisciplines,
  setCount,
  pageActual,
  filter
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = (await isBatchAction)
    ? createBatchActionProfessors(
        parseCreateProfessors({
          values,
          isBatchAction,
          selectedItem
        })
      )
    : createDetailsProfessors(
        parseCreateProfessors({ values, isBatchAction, ...selectedItem }),
        idDisc
      )

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateProfessors
    })
  }

  setSnack(MESSAGE.SUCCESS_ADD_PROFESSORS, SUCCESS)
  setSelected([])
  handleClose()

  return getListSCDisciplines(
    {
      id,
      setSnack,
      setListDisciplines,
      setCount,
      page: pageActual,
      filter
    },
    getOtherEndpoint
  )
}

const importIntegrateEvaluations = async ({
  id,
  values,
  setSnack,
  setLoadingOpen,
  handleClose
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await importIntegrateEvaluation(id, values)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateProfessors
    })
  }

  handleClose()
  return setSnack(MESSAGE.SUCCESS_IMPORTED_IA, SUCCESS)
}

const openFrequencyDiaryPDF = async ({
  classDisciplineId,
  classId,
  onFinish,
  onError
}) => {
  const pdf = PDF()

  const {
    data: frequencies,
    error,
    status
  } = await getFrequenciesToPDF({ classDisciplineId, classId })

  if (error) {
    return onError?.({ error, status })
  }

  if (!frequencies.lessons.length) {
    onFinish()
    return toastNotificationInfo(MESSAGE.FREQUENCY_LESSONS_EMPTY, 15000)
  }

  const docDefinitions = await FrequencyDiaryPDF(frequencies)
  return pdf.open({
    docDefinitions,
    onFinish,
    onError: (pdfError) => onError?.({ error: pdfError, status })
  })
}

const openNoteDiaryPDF = async ({
  classDisciplineId,
  classId,
  openURlLegacy,
  onFinish,
  onError
}) => {
  const pdf = PDF()

  const {
    data: scoresData,
    error,
    status
  } = await getScoreToPDF({ classDisciplineId, classId })

  if (error) {
    if (gradeRuleNotFound(error?.message?.error?.error)) {
      onFinish()
      return openURlLegacy()
    }
    return onError?.({ error, status })
  }

  if (
    scoresData.classInfo.unit === MATOGROSSO_UNIT ||
    (scoresData.classInfo.unit === NOVOTEC_UNIT &&
      new Date(scoresData.classInfo.classStartDate).getFullYear() <
        START_YEAR_FOR_NOVOTEC) ||
    yearGradeOldList.includes(scoresData.classInfo.yearGrade)
  ) {
    onFinish()
    return openURlLegacy()
  }

  if (!scoresData.students.length || !scoresData.hasGrades) {
    onFinish()
    return toastNotificationInfo(MESSAGE.NOTE_DIARY_EMPTY, 15000)
  }

  if (!scoresData.classInfo.responsibleTeachers.length) {
    onFinish()
    return toastNotificationInfo(MESSAGE.WITHOUT_RESPONSIBLE_TEACHERS, 15000)
  }

  const noteDiaryMethod =
    scoresData.classInfo.unit === NOVOTEC_UNIT
      ? noteDiaryNovotecPDF
      : noteDiaryGeneralPDF
  const docDefinitions = await noteDiaryMethod(scoresData)

  return pdf.open({
    docDefinitions,
    onFinish,
    onError: (pdfError) => onError?.({ error: pdfError, status })
  })
}

const openPartialReportPDF = async ({
  startDate,
  endDate,
  classId,
  onFinish,
  onError
}) => {
  const pdf = PDF()

  const {
    data: partialReport,
    error,
    status
  } = await getPartialReportToPDF({ classId, endDate, startDate })

  if (error) {
    return onError?.({ error, status })
  }

  if (!partialReport.students.length || !partialReport.subjects.length) {
    onFinish()
    return toastNotificationInfo(MESSAGE.REPORT_PARTIAL_EMPTY, 15000)
  }

  const docDefinitions = await PartialReportNovotecPDF(partialReport)

  return pdf.open({
    docDefinitions,
    onFinish,
    onError: (pdfError) => onError?.({ error: pdfError, status })
  })
}

const openSchoolReportPDF = async ({
  classId,
  onFinish,
  onError,
  openURlLegacy
}) => {
  const pdf = PDF()

  const {
    data: schoolReport,
    error,
    status
  } = await getSchoolReportToPDF({ classId })

  if (error) {
    if (gradeRuleNotFound(error?.message?.error?.error)) {
      onFinish()
      return openURlLegacy()
    }
    return onError?.({ error, status })
  }

  if (!schoolReport.students.length) {
    onFinish()
    return toastNotificationInfo(MESSAGE.SCHOOL_REPORT_EMPTY, 15000)
  }

  if (
    !schoolReport.classInfo.sumModule ||
    [UNITS.NOVOTEC.name, UNITS.MS.name].includes(schoolReport.classInfo.unit) ||
    yearGradeOldList.includes(schoolReport.classInfo.yearGrade)
  ) {
    onFinish()
    return openURlLegacy()
  }

  const docDefinitions = await SchoolReportPDF(schoolReport)

  return pdf.open({
    docDefinitions,
    onFinish,
    onError: (pdfError) => onError?.({ error: pdfError, status })
  })
}
const openSchoolReportXLSX = async ({
  classId,
  onFinish,
  onError,
  openURlLegacy
}) => {
  const pdf = PDF()

  const {
    data: schoolReport,
    error,
    status
  } = await getSchoolReportToXLSX({ classId })

  if (error) {
    if (gradeRuleNotFound(error?.message?.error?.error)) {
      onFinish()
      return openURlLegacy()
    }
    return onError?.({ error, status })
  }

  if (!schoolReport.students.length) {
    toastNotificationInfo(MESSAGE.SCHOOL_REPORT_EMPTY, 15000)
    return onFinish()
  }

  if (
    !schoolReport.classInfo.sumModule ||
    [UNITS.NOVOTEC.name, UNITS.MS.name].includes(schoolReport.classInfo.unit) ||
    yearGradeOldList.includes(schoolReport.classInfo.yearGrade)
  ) {
    onFinish()
    return openURlLegacy()
  }

  onFinish()

  return downloadXLSXByBuffer(schoolReport.content, schoolReport.reportName)
}

export {
  getDetails,
  updateSchoolClasses,
  updateSchoolClassesDisciplines,
  getExportFiles,
  getProfessors,
  handleCreateProfessors,
  createSchedules,
  getExportFilesPole,
  getListSCDisciplines,
  importIntegrateEvaluations,
  getAtaNovoTec,
  openFrequencyDiaryPDF,
  openNoteDiaryPDF,
  openPartialReportPDF,
  openSchoolReportPDF,
  openSchoolReportXLSX
}
