import { lazy, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'

import { ListAlt, Search } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'

import {
  useSnack,
  useMakeService,
  useActionSelected,
  usePaginationBasic
} from 'services/hooks'
import TAG_MANAGER from 'tagManager'
import { ERROR } from 'services/constants'
import { getErrorMessage, isEmpty, mergeInitialValues } from 'services/helpers'
import {
  getListGroups,
  getListEnrollmentsCourses
} from 'services/api/admin/comercial/courses'

import { cleanObject } from 'services/helpers/cleanObject'
import {
  MESSAGE,
  DEFAULT_PAGE,
  defaultTotalPage,
  enrollmentGroup,
  initialState
} from './constants'
import { breadcrumbsItems, selectListType } from './helpers'
import { useCourses } from '../../Management/Courses/ListCourse/Context/FormContext'

const FormNewEnrollment = lazy(() => import('./components/FormNewEnrollment'))
const TableNewEnrollment = lazy(() => import('./components/TableNewEnrollment'))
const ModalEnrollmentGroup = lazy(() =>
  import('./components/ModalEnrollmentGroup')
)

const NewEnrollment = () => {
  const [groups, setGroups] = useState([])
  const [notSearch, setNotSearch] = useState(true)
  const [listCourses, setListCourses] = useState([])
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [count, setCount] = useState(null)
  const { onActionSelected, selected, onResetAction } = useActionSelected()

  const location = useLocation()
  const isSearchParam = location?.state?.savedSearch

  const [filter, setFilter] = useState(defaultTotalPage)
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const paramsMakeService = {
    isUnits: true,
    isLevel: true,
    isPeriod: true,
    unitsValue: true,
    setSnack
  }
  const { unitys, level, period } = useMakeService(paramsMakeService)
  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(listCourses, filter, count)
  const { formValues, setFormValues, resetFormValues } = useCourses()

  const handleSearch = async (values) => {
    setLoadingVisibility(true)
    const params = cleanObject({
      ...(values.level && { level_name: values.level }),
      unit_id: Number(values.unity),
      course_name: values.course,
      is_ead: values.checked,
      course_shift: values.shift,
      limit: filter || defaultTotalPage,
      page: pageActual || DEFAULT_PAGE
    })

    const { data, error, status } = await getListEnrollmentsCourses(params)
    setLoadingVisibility(false)
    setFormValues(values)
    setCount(data?.count)

    if (error) {
      const message = getErrorMessage(error, status)
      return setSnack(message, ERROR)
    }

    setNotSearch(false)

    return setListCourses(data?.data)
  }

  const onClear = (setValues) => {
    setNotSearch(true)
    setValues({
      ...initialState
    })
    resetFormValues()
    setListCourses([])
  }

  const handleSelect = async (item) => {
    onActionSelected(enrollmentGroup, item)
    const { data } = await getListGroups()
    setGroups(selectListType(data))
  }

  useEffect(() => {
    if (formValues?.unity) handleSearch(formValues)
  }, [pageActual, filter])

  useEffect(() => {
    if (isSearchParam && formValues?.unity && formValues?.level) {
      return handleSearch(formValues)
    }

    return !isSearchParam && setFormValues(initialState)
  }, [isSearchParam])

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )
  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loadingVisibility}
      snackProps={snackProps}
      breadcrumbsItems={breadcrumbsItems}
      labelButtonHeader=""
      tagmanagerModal={TAG_MANAGER.secretary_btn_BackApplication}
      messageInformative={messageInformative}
      listItems={listCourses}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      content={
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSearch}
            enableReinitialize
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit} noValidate autoComplete="off">
                <FormNewEnrollment
                  {...props}
                  level={level}
                  unitys={unitys}
                  period={period}
                  onClear={onClear}
                  setSnack={setSnack}
                />
              </Form>
            )}
          </Formik>

          {listCourses && !isEmpty(listCourses) && !notSearch && (
            <TableNewEnrollment
              filter={filter}
              setPage={setPage}
              pageActual={pageActual}
              totalPage={totalPage}
              setFilter={setFilter}
              rowsCell={listCourses}
              onEnrollmentGroup={(item) => handleSelect(item)}
            />
          )}

          <ModalEnrollmentGroup
            groups={groups}
            setSnack={setSnack}
            handleClose={onResetAction}
            selected={selected?.data}
            open={selected?.type === enrollmentGroup}
            onInactivateTitle={(item) =>
              onActionSelected(enrollmentGroup, item)
            }
          />
        </>
      }
    />
  )
}
export default NewEnrollment
