import styled from 'styled-components'
import { Typography } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import { Modal as MuiModal } from 'components/atoms'

const Container = styled.div`
  min-width: 300px;
  min-height: 160px;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: ${({ theme }) => theme.palette.background.white};
  padding: 24px;
`

const Header = styled.div``

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const IconText = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px 190px;
  grid-gap: 24px;
  margin-bottom: 24px;
`

const Modal = styled(MuiModal)`
  & .MuiDialog-paper {
    max-width: 760px;
    min-width: 760px;
    width: 100%;
    background-color: red !important;
  }
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const CardInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin: 16px 0;
  div:first-child {
    > div:first-child {
      align-items: flex-start !important;
    }
  }
`
const BoxInfo = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`

export {
  Container,
  Header,
  Title,
  IconText,
  Box,
  Modal,
  IconWarningAmber,
  CardInfo,
  BoxInfo
}
