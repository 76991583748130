const ENROLLMENTS_ROUTES = 'enrollments'
const PRE_ENROLLMENTS_ROUTES = 'enrollments/pre-enrollment'
const MANUAL_ENROLLMENTS_ROUTES = 'enrollments/manual-enrollment'
const DIRECT_ENROLLMENTS_ROUTES = 'enrollments/direct-enrollment'

const COMMERCIAL = {
  GET_STUDENT_CPF: 'students/search-cpf',
  LIST_GROUPS: `${ENROLLMENTS_ROUTES}/groups`,
  GET_CAMPAIGN: `${ENROLLMENTS_ROUTES}/campaign`,
  VALIDATE_AGE: `${ENROLLMENTS_ROUTES}/validate-age`,
  VALIDATE_COUPON: `${PRE_ENROLLMENTS_ROUTES}/coupon`,
  LIST_MATRICULATION: `${PRE_ENROLLMENTS_ROUTES}/courses`,
  BASE_URL: `${PRE_ENROLLMENTS_ROUTES}`,
  BASE_URL_MANUAL: `${MANUAL_ENROLLMENTS_ROUTES}`,
  BASE_URL_DIRECT: `${DIRECT_ENROLLMENTS_ROUTES}`
}

export { COMMERCIAL }
