const FEEDBACK_OCCURRENCE = {
  noAccessPermissionToListCategory: 'para listar categoria(s)',
  noAccessPermissionToListTypeCategory: 'para listar tipo de categoria(s)',
  noAccessPermissionToViewOccurrence: 'para visualizar ocorrência',
  noAccessPermissionToListCourse: 'para listar curso(S)',
  noAccessPermissionToListSchollClass: 'para listar turma(s)',
  noAccessPermissionToFindStudent: 'para buscar aluno(a)',
  noAccessPermissionToListOccurrence: 'para listar ocorrência(s)',
  noAccessPermissionToAddComplement: 'para adicionar complemento',
  noAccessPermissionToRemoveOccurrence: 'para inativar ocorrência(s)'
}

const FEEDBACK_SCHOOL_CLASS = {
  noAccessPermissionToRedirectDashboard: 'para acessar o dashboard',
  noAccessPermissionToListStudentClass: 'para enturmar aluno(s)',
  noAccessPermissionToCreateSchoolClass: 'para criar uma nova turma',
  noAccessPermissionToListEditDiscipline: 'para adicionar disciplina',
  noAccessPermissionToDeleteClass: 'para deletar turma(s)',
  noAccessPermissionToListReenroll: 'para inativar rematrícula(s)',
  noAccessPermissionToListGradeSheets: 'para listar matriz curricular',
  noAccessPermissionToPartialReport: 'para gerar ata parcial',
  noAccessPermissionToSchoolReport: 'para gerar boletim escolar'
}

const FEEDBACK_REQUISITION = {
  noAccessPermissionToListRequisition: 'para listar requerimento',
  noAccessPermissionToListRequisitionID: 'para listar requerimento unico',
  noAccessPermissionToListRequisitionHistoric:
    'para listar historico do requerimento',
  noAccessPermissionToUpdateRequisition: 'para atualizar requerimento',
  noAccessPermissionToCreateRequisition: 'para criar requerimento'
}

const FEEDBACK_STUDENTS = {
  noAccessPermissionToCreateExpedition: 'para criar uma expedição',
  noAccessPermissionToDeleteExpedition: 'para deletar expedição',
  noAccessPermissionToListExpedition: 'para lista expedição',
  noAccessPermissionToEditExpedition: 'para editar uma expedição',
  noAccessPermissionToListStudents: 'para listar aluno(s)',
  noAccessPermissionToStudentInfo: 'para consultar aluno(s)',
  noAccessPermissionToUploadStudentPhoto:
    'para fazer upload de foto de aluno(s)',
  noAccessPermissionToResetPassword: 'para resetar senha de aluno(s)',
  noAccessPermissionToDeleteClass: 'para deletar turma(s)',
  noAccessPermissionToListReenroll: 'para listar rematrícula(s)',
  noAccessPermissionToInactiveListReenroll: 'para inativar rematrícula(s)',
  noAccessPermissionToListEditDiscipline: 'para adicionar disciplina',
  noAccessPermissionToPdfSummaryReenroll: 'para baixar resumo em PDF',
  noAccessPermissionToNewRegistration: 'para incluir nova rematrícula',
  noAccessPermissionToSendJoy: 'enviar para Joy',
  noAccessPermissionToSendPendingBillingEmail:
    'para enviar boleto pendente de aluno(s)',
  noAccessPermissionToExportGeneralReport: 'para exportar reatórios gearais',
  noAccessPermissionToSendEmail: 'para enviar e-mails',
  noAccessPermissionToRedirectDashboard: 'para acessar o dashboard',
  noAccessPermissionToRedirectPdf: 'para acessar o pdf',
  noAccessPermissionDeleteDeclaration: 'para deletar declaração',
  noAccessPermissionToListStudentsInteractions:
    'para listar interações de aluno(s)'
}
const FEEDBACK_DECLARETIONS = {
  noAccessPermissionToListDeclaretion: 'para listar declarações',
  noAccessPermissionToDeleteDeclaretion: 'para deletar declarações',
  noAccessPermissionToEditDeclaretion: 'para editar declarações',
  noAccessPermissionToCreateDeclaretion: 'para criar declarações'
}

const FEEDBACK_DOCUMENTS = {
  noAccessPermissionToListDocumentStatus: 'para listar status de documento(s)',
  noAccessPermissionToDownloadListDocumentStatus:
    'para baixar resumo de status de documento(s)'
}

const FEEDBACK_CONTRACTS = {
  noAccessPermissionToListContractStatus: 'para listar status de contrato(s)',
  noAccessPermissionToEditContractStatus: 'para editar status de contrato(s)',
  noAccessPermissionToDownloadListContractStatus:
    'para baixar resumo de status de contrato(s)'
}

const FEEDBACK_ACADEMIC_PERFORMANCES = {
  noAccessPermissionToListAcademicPerformance:
    'para listar aproveitamento de estudo(s)',
  noAccessPermissionToListAcademicPerformanceDiscipline:
    'para listar disciplinas de aproveitamento de estudo(s)'
}

const FEEDBACK_DASHBOARD = {
  noAccessPermissionToDetailsDashboar: 'para ver o dashboard'
}

export const FEEDBACK_SECRETARY = {
  ...FEEDBACK_DASHBOARD,
  ...FEEDBACK_OCCURRENCE,
  ...FEEDBACK_SCHOOL_CLASS,
  ...FEEDBACK_REQUISITION,
  ...FEEDBACK_STUDENTS,
  ...FEEDBACK_DOCUMENTS,
  ...FEEDBACK_CONTRACTS,
  ...FEEDBACK_DECLARETIONS,
  ...FEEDBACK_ACADEMIC_PERFORMANCES
}
