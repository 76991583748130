import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { Clear, ListAlt, Search } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import {
  useActionSelected,
  useMakeService,
  usePaginationBasic,
  useSnack
} from 'services/hooks'
import { UF_STATE_BRAZIL, isEmpty } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import { ButtonFooterV2 } from 'components/molecules'
import { useNavigate } from 'react-router-dom'
import { ROUTE } from 'services/constants'
import {
  MESSAGE,
  initialState,
  viewBankingBilling,
  defaultItemsPerPage,
  modalManualEnrollment,
  modalDuplicateEnrollment,
  modalConfirm
} from './constants'
import { handleDetails, handleDetailsCopy, handleSearch } from './services'

import FormListEnrollment from './components/FormListEnrollment'
import { TableEnrollment } from './components/TableEnrollment'
import ModalManualEnrollment from './components/ModalManualEnrollment'
import { isDisabledButton } from './helpers'
import ModalManualDuplicateEnrollment from './components/ModalManualDuplicateEnrollment'
import * as Styled from './style'

const Enrollment = () => {
  const navigate = useNavigate()
  const [count, setCount] = useState(null)
  const [details, setDetails] = useState({})
  const [detailsCopy, setDetailsCopy] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [listRegistration, setListRegistration] = useState([])
  const [loadingVisibility, setLoadingVisibility] = useState(false)

  const [filter, setFilter] = useState(defaultItemsPerPage)

  const { onActionSelected, selected, onResetAction } = useActionSelected()
  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(listRegistration, filter, count)

  const isViewBankingBilling = selected?.type === viewBankingBilling
  const snackProps = useSnack()
  const { setSnack } = snackProps

  const paramsMakeService = {
    isUnits: true,
    unitsValue: true,
    setSnack
  }

  const { unitys } = useMakeService(paramsMakeService)

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: (values) => {
      handleSearch({
        values,
        setSnack,
        setLoadingVisibility,
        filter,
        pageActual,
        setListRegistration,
        setNotSearch,
        setCount
      })
    }
  })

  useEffect(() => {
    if ((formik.values.beginAt && formik.values.endAt) || formik.values.cpf)
      handleSearch({
        values: formik.values,
        setSnack,
        setLoadingVisibility,
        filter,
        pageActual,
        setListRegistration,
        setNotSearch,
        setCount
      })
  }, [pageActual, filter, isUpdate])

  useEffect(() => {
    handleDetails({
      setDetails,
      isViewBankingBilling,
      id: selected.data?.register
    })
    handleDetailsCopy({
      setDetailsCopy,
      isViewBankingBilling,
      id: selected.data?.register
    })
  }, [isOpenModal])

  return (
    <TemplateDefaultHeaderByContent
      titleHeader="<b>Matrícula Manual</b>"
      snackProps={snackProps}
      listItems={listRegistration}
      labelButtonHeader="Pré Matricula"
      messageInformative={messageInformative}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      classNameHeader={TAG_MANAGER.btn_commercial_starts_new_registration}
      onClickButton={() => {
        navigate(ROUTE.ADMIN_COMMERCIAL_REGISTRATION_CREATE)
      }}
      loadingOpen={loadingVisibility}
      content={
        <>
          <Styled.Form
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
              <FormListEnrollment
                values={formik.values}
                unitys={unitys}
                resetForm={formik.resetForm}
                setFieldValue={formik.setFieldValue}
                stateOptions={UF_STATE_BRAZIL}
                setNotFoundSearct={setNotSearch}
                setListRegistration={setListRegistration}
              />

              <Styled.Grid item xs={12} sm={12} md={3}>
                <ButtonFooterV2
                  size="medium"
                  labelClose="Limpar"
                  labelConfirm="Buscar"
                  startIcon={<Clear />}
                  disabledConfirm={isDisabledButton(formik.values)}
                  classNameConfirm={
                    TAG_MANAGER.commercial_btn_search_for_registrations
                  }
                  onClickClose={() => {
                    formik.resetForm({})
                    setNotSearch(true)
                    setListRegistration([])
                  }}
                />
              </Styled.Grid>
            </Styled.GridContainer>
          </Styled.Form>

          {listRegistration && !isEmpty(listRegistration) && !notSearch && (
            <TableEnrollment
              count={count}
              filter={filter}
              setPage={setPage}
              totalPage={totalPage}
              setFilter={setFilter}
              items={listRegistration}
              pageActual={pageActual}
              onManualEnrollment={(type, item) => {
                setIsOpenModal(!isOpenModal)
                onActionSelected(type, item)
              }}
            />
          )}

          <ModalManualEnrollment
            isOpen={isOpen}
            details={details}
            setSnack={setSnack}
            selected={selected}
            setIsOpen={setIsOpen}
            detailsCopy={detailsCopy}
            handleClose={() => {
              setDetails({})
              onResetAction()
            }}
            onResetAction={() => {
              setIsOpen(false)
              handleSearch({
                values: formik.values,
                setSnack,
                setLoadingVisibility,
                filter,
                pageActual,
                setListRegistration,
                setNotSearch,
                setCount
              })
            }}
            onActionSelected={onActionSelected}
            open={selected?.type === modalManualEnrollment}
          />

          <ModalManualDuplicateEnrollment
            details={details}
            setSnack={setSnack}
            detailsCopy={detailsCopy}
            handleClose={() => {
              setDetails({})
              onResetAction()
            }}
            callbackSuccess={() => {
              setIsUpdate(true)
              onActionSelected(modalConfirm, {})
            }}
            open={selected?.type === modalDuplicateEnrollment}
          />
        </>
      }
    />
  )
}
export default Enrollment
