import { Download, Person } from '@mui/icons-material'
import { ROUTE } from 'services/constants'

const defaultItemsPerPage = 10
const defaultPage = 1
const defaultTotalPage = 10
const idGrades = 'grades'
const idNameList = 'id'
const idDash = 'dash'
const getOtherEndpoint = true
const dateNull = '0000-00-00 00:00:00'

const MESSAGE = {
  SUCCESS_ADD_PROFESSORS: 'Professor(es) criado(s) com sucesso!',
  SUCCESS_IMPORTED_IA: 'Avaliação integrada importada com sucesso!',
  SUCCESS_UPDATE: 'Turma atualizada com sucesso!',
  SUCCESS_GRADES_EXPORT: 'Notas exportadas com sucesso!',
  SUCCESS_RESULT_TRANSCRIPT: 'Ata de Resultados exportadas com sucesso!',
  SUCCESS_UPDATE_DISCIPLINES: 'Disciplina(s) atualizada(s) com sucesso!',
  SUCCESS_CREATE_SCHEDULES: 'Horários criados com sucesso!',
  SUCCESS_DELETE_SCHEDULE: 'Horários deletado com sucesso!',
  SUCCESS_ESPORT_ATTENDANCE_REPORT:
    'Relatório de presença exportado com sucesso!',
  ERROR_DATA_START:
    'A data de INICIO precisa ser menor ou igual a do que a data de FIM',
  ERROR_DATA_END:
    'A data de FIM precisa ser maior ou igual a do que a data de INICIO',
  ERROR_DATA: 'Precisar ser uma data válida',
  FREQUENCY_LESSONS_EMPTY: 'Não há aulas para essa disciplina',
  NOTE_DIARY_EMPTY: 'Não há alunos e/ou atividades para essa disciplina.',
  REPORT_PARTIAL_EMPTY:
    'Não há alunos e/ou disciplinas para essa turma nesse período.',
  SCHOOL_REPORT_EMPTY: 'Não há alunos nesta turma.',
  WITHOUT_RESPONSIBLE_TEACHERS:
    'Não há professores vinculados para essa disciplina.'
}

const breadCrumbsItems = (id) => [
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
    label: 'Turmas'
  },
  {
    path: `${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS}?id=${id}`,
    label: '<b>Detalhes da Turma</b>'
  }
]

const initialFormValues = {
  beginAt: '',
  buttonPole: false,
  code: '',
  endAt: '',
  gradeYear: '',
  id: '',
  qtyClass: '',
  supervisor: '',
  lessonDuration: '',
  pole: '',
  resolution: '',
  observation: ''
}

const initialStaticFormValues = {
  unit: '',
  pole: '',
  buttonPole: '',
  group: '',
  avaliationMethod: '',
  observation: '',
  modality: '',
  level: '',
  course: '',
  shift: '',
  module: '',
  minimumGrade: '',
  recoveryMin: '',
  minimumGradeRecovery: '',
  minimumFrequency: ''
}

const initialBigNumbers = {
  classId: 0,
  students: 0,
  inactive: 0,
  active: 0,
  binded: 0
}

const colors = ['#593493', '#4caf50', '#2196F3', '#F44336']

const icons = [
  {
    icon: <Person />
  },
  {
    icon: <Download />
  },
  {
    icon: <Download />
  },
  {
    icon: <Download />
  }
]

const lisBigNumbers = ({ students, inactive, active, binded, idTurma }) => [
  {
    path: `${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_BIND_UNBINDED}?id=${idTurma}`,
    hasLink: true,
    number: students,
    label: 'Total de Alunos',
    description: 'ENTURMAR ALUNOS'
  },
  {
    path: `acesso/turma_lista_alunos_pdf.php?id_turma=${idTurma}&modeloL=1`,
    number: active,
    label: 'Alunos Ativos',
    description: 'EXPORTAR LISTA'
  },
  {
    path: `acesso/turma_lista_alunos_vinculados_pdf.php?id_turma=${idTurma}`,
    number: binded,
    label: 'Alunos Vinculados',
    description: 'EXPORTAR LISTA'
  },
  {
    path: `acesso/turma_lista_alunos_pdf.php?id_turma=${idTurma}&modeloL=2`,
    number: inactive,
    label: 'Alunos Inativos',
    description: 'EXPORTAR LISTA'
  }
]

const START_YEAR_FOR_NOVOTEC = 2024

export {
  breadCrumbsItems,
  colors,
  lisBigNumbers,
  icons,
  defaultItemsPerPage,
  defaultPage,
  defaultTotalPage,
  initialFormValues,
  initialStaticFormValues,
  initialBigNumbers,
  idGrades,
  MESSAGE,
  getOtherEndpoint,
  idDash,
  dateNull,
  idNameList,
  START_YEAR_FOR_NOVOTEC
}
