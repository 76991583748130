import * as yup from 'yup'

const startDateSchema = yup
  .string()
  .nullable()
  .required('A data de início deve ser válida e não pode estar em branco')

const endDateSchema = yup
  .string()
  .nullable()
  .required('A data de término deve ser válida e não pode estar em branco')

const dateSchema = yup.object().shape({
  startDate: startDateSchema,
  endDate: endDateSchema
})

const schema = yup.object().shape({
  unity: yup.string().required('A unidade é obrigatória'),
  group: yup.string().required('O grupo é obrigatório'),
  name: yup.string().required('O nome da turma é obrigatório'),
  method: yup.string().required('O método de avaliação é obrigatório'),
  startDate: yup.string().nullable().required('A data inicial é obrigatório'),
  endDate: yup.string().nullable().required('A data final é obrigatório'),
  modality: yup.string().required('A modalidade é obrigatório'),
  level: yup.string().required('O nível é obrigatório'),
  course: yup.string().required('O curso é obrigatório'),
  curriculum: yup.string().required('A matriz curricular é obrigatória'),
  module: yup.string().required('O módulo é obrigatório'),
  shift: yup.string().required('O periodo é obrigatório'),
  approval: yup.string().required('A nota mínima para aprovação é obrigatória'),
  recovery: yup
    .string()
    .required('A nota mínima para recuperação é obrigatória'),
  classDuration: yup.string().required('A duração da aula é obrigatória'),
  absences: yup
    .string()
    .required('A porcentagem máxima de faltas é obrigatória'),
  reEnrollmentDates: yup.array().of(dateSchema).required()
})

export { schema }
