const FEEDBACK_SUBJECTS = {
  noAccessPermissionToListSubjects: 'para listar disciplinas',
  noAccessPermissionToListRegisterEadContent: 'para listar os cadastro de EAD',
  noAccessPermissionToListLevelCourses: 'para listar Cursos',
  noAccessPermissionToListListJoyCourses: 'para listar Cursos da Joy',
  noAccessPermissionToSaveSubjects: 'para salvar disciplinas',
  noAccessPermissionToUpdateSubjects: 'para atualizar disciplinas',
  noAccessPermissionToUpdateTeachingPlan: 'para atualizar plano de ensino',
  noAccessPermissionToViewSubjects: 'para vizualizar disciplinas'
}

const FEEDBACK_CURRICULUM = {
  noAccessPermissionToListCurriculum: 'para listar matrizes',
  noAccessPermissionToListLevelCourses: 'para listar cursos',
  noAccessPermissionToSaveCurriculum: 'para salvar matrizes',
  noAccessPermissionToUpdateCurriculum: 'para atualizar matrizes',
  noAccessPermissionToViewCurriculum: 'para vizualizar matrizes',
  noAccessPermissionToInactiveCurriculum: 'para inavitar matrizes'
}

const FEEDBACK_OCCURRENCE_TYPES = {
  noAccessPermissionToListOccurrenciesTypes: 'para listar Tipos de ocorrências',
  noAccessPermissionToCopyOccurrenciesTypes: 'para copiar Tipos de ocorrências',
  noAccessPermissionToInactiveOccurrenciesTypes:
    'para inativar Tipos de ocorrências',
  noAccessPermissionToActiveOccurrenciesTypes:
    'para ativar Tipos de ocorrências',
  noAccessPermissionToListCategoriesOccurrenciesTypes:
    'para listar as Categorias de tipos de ocorrências',
  noAccessPermissionToListEventsOccurrenciesTypes:
    'para listar os Eventos de tipos de ocorrências'
}

const FEEDBACK_TYPES_REQUIREMENTS = {
  noAccessPermissionToListTypesRequirements:
    'para listar Tipos de Requerimento',
  noAccessPermissionToListCourseTypesRequirements:
    'para listar cursos em Tipos de Requerimento',
  noAccessPermissionToInactiveTypesRequirements:
    'para inativar Tipos de Requerimento',
  noAccessPermissionToCopyTypesRequirements:
    'para copiar Tipos de Requerimento',
  noAccessPermissionToDeleteTypesRequirements:
    'para deletar Tipos de Requerimento',
  noAccessPermissionToCreateTypesRequirements:
    'para criar Tipos de Requerimento'
}

const FEEDBACK_TYPES_ACTIVITY = {
  noAccessPermissionToListTypesActivitys: 'para listar Tipos de Atividade',
  noAccessPermissionToDeleteTypesActivitys: 'para deletar Tipos de Atividade',
  noAccessPermissionToEditTypesActivitys: 'para editar Tipos de Atividade',
  noAccessPermissionToEditStatusActivitys: 'para editar o status da Atividade',
  noAccessPermissionToCreateTypesActivitys: 'para criar Tipos de Atividade'
}

export const FEEDBACK_MANAGEMENT = {
  ...FEEDBACK_SUBJECTS,
  ...FEEDBACK_TYPES_ACTIVITY,
  ...FEEDBACK_OCCURRENCE_TYPES,
  ...FEEDBACK_TYPES_REQUIREMENTS,
  ...FEEDBACK_CURRICULUM
}
