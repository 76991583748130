const SECRETARY = {
  OCCURRENCE: {
    CRUD: 'occurrences',
    CATEGORY_LIST: 'occurrences/types/categories',
    LIST_TYPE_OF_OCCURRENCE: 'occurrences/secretary-type',
    DELETE: 'occurrences/deleted'
  },
  REQUIRIMENT: {
    CRUD: 'requirements',
    STATUS: 'requirements/status',
    SECTOR: '/sector',
    SECRETARY_TYPES: 'requirements/secretary-types',
    COURSE_SCHOOL: 'requirements/secretary-types/course',
    STUDENT_DETAILS: '/students/details',
    RESPONSIBLE: 'requirements/responsible-sector',
    HISTORIC: '/requirements/historic',
    XLSX: '/requirements/xlsx/download',
    COUNTER: '/requirements/counters',
    EXISTING: '/requirements/student'
  },
  SCHOOL_CLASS: {
    CRUD: 'school-classes',
    LIST: 'search',
    SEND_JOY: 'send-to-joy',
    SEND_EMAIL: 'send-email',
    DELETE: 'schoolClass/deleted',
    RE_ENROLLMENT: 're-enrollments',
    INACTIVE: 're-enrollments/inactivate',
    SECRETARY_TYPES: 'requirements/secretary-types',
    DISCIPLINES: 'disciplines',
    OCURRENCE_DOWNLOAD: 'occurrences/report/xlsx',
    STUDENTS: 'students',
    BIND: 'binded',
    BIND_STUDENTS: 'bind-students',
    UNBINDED_STUDENTS: 'unbind-students',
    UNBINDED: 'unbinded',
    IS_EAD: 'is_ead',
    BIG_NUMBERS: 'counters',
    GRADE_SHEETS: 'school-classes/grade-sheets',
    GRADES_FILES: 'reports/grade-map',
    RESULT_TRANSCRIPT: 'reports/record-results',
    PROFESSORS: 'professors',
    PROFESSOR: 'professor',
    SCHEDULES: 'class-schedules',
    GENERAL_REPORT: 'reports/general',
    JOY: 'joy',
    COURSES: 'courses',
    CREATE: 'school-classes',
    SUBJECT: 'subjects',
    INTEGRATED_EVALUATION: 'integrated-evaluation',
    SHIFTS: 'shifts',
    MODULES: 'modules',
    LEVELS: 'levels',
    MODALITIES: 'modalities',
    EVALUATIONS: 'evaluations',
    COORDINATORS: 'coordinators',
    SCORES: 'scores'
  },
  ADMIN_SECRETARY_STUDENT: {
    DISCIPLINES_LINK_STUDENT: (register) =>
      `disciplines/student/${register}/link`,
    DISCIPLINES_LINKED: 'disciplines/linked',
    DISCIPLINES_LINK_SUBJECT: 'disciplines/student/link-subject',
    DISCIPLINES_CLASS: 'school-classes/student/disciplines',
    LIST: 'student-management/secretary',
    LIST_JUSTIFY_ABSENCE: 'justification/absence',
    STUDENT_INFO: 'student-management/secretary/student',
    POST_JUSTIFY_ABSENCE: 'justification/absence',
    PHOTO_UPLOAD: 'student-management/secretary/profile/image/upload',
    RESET_PASSWORD: 'student-management/secretary/:register/password-reset',
    SECTOR: '/sector',
    SECRETARY_TYPES: 'requirements/secretary-types',
    CRUD: '/student-management/secretary/course',
    LIST_SCHOOL: '/school-classes/search-all',
    LIST_CLASS: '/school-classes/student',
    CONTRACT: '/student-management/secretary/student/contract',
    SMS: '/student-management/secretary/student/send_sms',
    LIST_EMAIL_LOGS: (register) => `students/${register}/email/logs`,
    EMAIL: 'student-management/secretary/student/send_email',
    LIST_SMS_LOGS: (register) => `students/${register}/sms/logs`,
    LIST_WHATSAPP_LOGS: (register) => `students/${register}/whatsapp/logs`,
    DISABILITIES_TYPES: 'students/disabilities-types',
    SEND_PENDING_BILLING_EMAIL: 'email-send/billing/pending',
    LIST_DOCUMENTS: 'documents/student',
    SELECT_DOCUMENTS: 'documents/types?type=A',
    UPDATE_SITUATION: 'documents/situation',
    LIST_DECLARATION: 'documents/student',
    LIST_DOCUMENT: 'documents/declaration/model',
    CREATE_DECLARATION: 'acesso/aluno_declaracao_pdf.php',
    DOCUMENT_DECLARATION: 'documents/declaration',
    DELETE_DOCUMENT: 'documents/student',
    LIST_MODULES: 'school-classes/modalities',
    REQUEST_UTILIZATION: 'academic-performance/solicitation',
    LIST_DISCIPLINE_BY_COURSE_STUDENT: 'academic-performance/student',
    LIST_JUSTIFY_USE_OF_STUDIES: 'academic-performance/justification',
    LIST_INCOME_TAX: 'bank/billing/student',
    COURSES_STUDENT: 'courses/student',
    BASE_URL_EXPEDITION: 'documents/expedition',
    BASE_URL_STUDENT_EXPEDITION: 'documents/student/expedition'
  },
  ADMIN_SECRETARY_GROUPING: {
    LIST: '/groupings',
    TEACHERS_LIST: '/groupings/professors',
    UPDATE_TEACHER: '/groupings/add-professor',
    SITUATIONS_LIST: '/groupings/situations',
    INCLUDE_DISCIPLINE: '/groupings/include',
    DISCIPLINES_TO_ADD_IN_GROUPING: '/groupings/add-discipline/search',
    DISCIPLINES_CRUD: '/groupings/disciplines'
  },
  ADMIN_SECRETARY_ADDITIONAL_ACTIVITIES: {
    SEARCH: 'complementary-activity/filter'
  },
  ADMIN_SECRETARY_DOCUMENT: {
    LIST_STATUS: 'documents/status',
    LIST_STATUS_XLSX: 'documents/status/xlsx',
    STUDENT: 'documents/student'
  },
  ADMIN_SECRETARY_CONTRACT: {
    LIST_STATUS: 'contracts/status',
    LIST_STATUS_XLSX: 'contracts/status/xlsx'
  },
  ADMIN_ACADEMIC_PERFORMANCE: {
    CRUD: 'academic-performance/secretary',
    DISCIPLINES: 'academic-performance/student'
  },
  DASHBOARD_SCHOOL: {
    BASE_URL: 'dashboard',
    BASE_URL_EMAIL: 'mail/dashboard'
  }
}

export { SECRETARY }
