import { useState } from 'react'
import { Form, Formik } from 'formik'
import { Grid, InputAdornment } from '@mui/material'

import { ButtonFooter, Select, TextField } from 'components/molecules'
import { BoxItem } from 'components/atoms'
import { cleanObject } from 'services/helpers/cleanObject'
import { registrationCopy } from 'services/api/admin/comercial/courses'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { SUCCESS } from 'services/constants'
import { formSchema } from '../../schema'
import * as Styled from './style'
import { remapInstallmentsToSelect } from './helpers'

const ModalManualDuplicateEnrollment = ({
  open,
  details,
  setSnack,
  detailsCopy,
  handleClose,
  callbackSuccess
}) => {
  const [list, setList] = useState([])

  const confirmCopy = async (values) => {
    setSnack('', '')

    const params = cleanObject({
      discount: values.inCash,
      enrollment_id: values.course,
      installments: values.payment,
      discount_reason: values.discount_reason
        ? values?.discount_reason.toUpperCase()
        : ''
    })

    const { error, status } = await registrationCopy(params, details.register)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToForm
      })
    }

    setSnack('Matrícula duplicada com sucesso.', SUCCESS)

    return setTimeout(callbackSuccess, 1500)
  }

  const handleSetFieldValue = ({ value, setFieldValue }) => {
    const selected = detailsCopy?.courseData.find(
      (item) => item.value === value
    )
    remapInstallmentsToSelect({ setList, details: [selected] })

    setFieldValue('course', selected.value)
    setFieldValue('payment', selected.installments)
  }

  return (
    <>
      <Styled.Modal open={open} onClose={handleClose}>
        <Formik
          enableReinitialize
          onSubmit={confirmCopy}
          validationSchema={formSchema}
          initialValues={{
            payment: '',
            inCash: details?.cash_discount,
            discount_reason: details?.discount_reason
          }}
        >
          {({ setFieldValue, values, handleSubmit, handleChange }) => (
            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Styled.Container>
                <Styled.Header>
                  <Styled.IconText>
                    <Styled.Title variant="h5">Duplicar Matrícula</Styled.Title>
                  </Styled.IconText>
                  <Styled.CardInfo>
                    <Styled.BoxInfo>
                      <BoxItem
                        title="Modalidade Atual:"
                        subTitle={details?.modality}
                      />
                    </Styled.BoxInfo>
                  </Styled.CardInfo>

                  <Grid container spacing={{ xs: 0, sm: 2 }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Select
                        fullWidth
                        id="course"
                        optionsList={detailsCopy?.courseData}
                        value={values.course}
                        label="Cursos Disponíveis"
                        onChange={(e) => {
                          handleSetFieldValue({
                            value: e.target.value,
                            setFieldValue
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Select
                        fullWidth
                        id="payment"
                        optionsList={list}
                        value={values.payment}
                        defaultValue={list[0]?.value}
                        label="Plano de pagamento"
                        onChange={(e) => {
                          setFieldValue('payment', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        id="inCash"
                        type="text"
                        name="inCash"
                        label="Desconto à vista"
                        InputProps={{
                          value: values.inCash || '',
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          )
                        }}
                        onChange={(e) =>
                          setFieldValue('inCash', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        type="text"
                        name="discount_reason"
                        onChange={handleChange}
                        label="Motivo do desconto"
                        value={values.discount_reason || ''}
                      />
                    </Grid>
                  </Grid>
                </Styled.Header>

                <ButtonFooter
                  textButtonConfirm="Duplicar"
                  textButtonNotConfirm="Cancelar"
                  onClickNotConfirm={handleClose}
                  disabledConfirm={!values?.payment}
                />
              </Styled.Container>
            </Form>
          )}
        </Formik>
      </Styled.Modal>
    </>
  )
}
export default ModalManualDuplicateEnrollment
