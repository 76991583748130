import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import * as Styled from './style'

const BoxItem = ({ title, subTitle }) => (
  <Styled.BoxItem>
    <Typography variant="subtitle2" fontWeight="bold">
      {title}
    </Typography>
    <Typography variant="subtitle2" ml={1}>
      {subTitle}
    </Typography>
  </Styled.BoxItem>
)

BoxItem.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
}

export default BoxItem
