import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Tab, Tabs as MuiTabs } from '@mui/material'
import TabContents from './TabContents'
import * as Styled from './style'

const Tabs = ({
  contents,
  defaultTab,
  disabledTabs,
  navigations,
  onClickTab,
  justifyContent,
  borderTop,
  borderRight,
  borderLeft,
  isNovoTech
}) => {
  const [value, setValue] = useState(defaultTab)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (defaultTab) setValue(defaultTab)
  }, [defaultTab])

  return (
    <>
      <Styled.Box
        $borderBottom={1}
        $borderTop={borderTop}
        $borderRight={borderRight}
        $borderLeft={borderLeft}
        $borderColor="divider"
        $justifyContent={justifyContent}
      >
        <MuiTabs
          onChange={handleChange}
          scrollButtons="auto"
          value={value}
          variant="scrollable"
        >
          {navigations.map((navigation, index) => (
            <Tab
              aria-controls={`default-tab-content-${index}`}
              disabled={disabledTabs?.includes(index)}
              id={`default-tab-${index}`}
              key={navigation}
              label={navigation}
              onClick={() => onClickTab({ tabIndex: value, navigation })}
            />
          ))}
        </MuiTabs>
      </Styled.Box>

      {contents.map((content, index) => {
        const contentKey = `tab-content-${index}`
        return (
          <Styled.WrapperContent key={contentKey}>
            <TabContents index={index} key={contentKey} value={value}>
              {content}
            </TabContents>
          </Styled.WrapperContent>
        )
      })}
    </>
  )
}

export default Tabs

Tabs.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.element).isRequired,
  defaultTab: PropTypes.number,
  disabledTabs: PropTypes.arrayOf(PropTypes.number),
  navigations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickTab: PropTypes.func
}

Tabs.defaultProps = {
  defaultTab: 0,
  onClickTab: () => {}
}
