import styled, { css } from 'styled-components'
import {
  List as MuiList,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
  Grid as MuiGrid
} from '@mui/material'

const List = styled(MuiList)`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  > li:first-child {
    margin-bottom: 8px;
  }
`

const ListItem = styled(MuiListItem)`
  padding: 0px 2px 0 0 !important;
  border-bottom: 1.5px solid #e0e0e0;
  justify-content: space-between !important;
`
const ListItemText = styled(MuiListItemText)`
  ${({ $isTitle }) =>
    $isTitle &&
    css`
      > span:first-child {
        font-weight: 500;
      }
    `}
  ${({ $small }) =>
    $small &&
    css`
      width: 75px;
      flex: auto;
      max-width: 67px;
      text-align: end;
    `}
`
const Container = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`
const ContainerChartBar = styled(Container)`
  gap: 4px;
  height: 152px;
  overflow: hidden;
  align-items: flex-end;
  justify-content: flex-start;
`

const ContainerCards = styled(Container)`
  > div {
    flex: 1 0 260px;
    > div {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .MuiPaper-root {
    .MuiButtonBase-root {
      cursor: initial;
    }
  }
`
const Grid = styled(MuiGrid)`
  ${({ isligth }) =>
    isligth &&
    css`
      background-color: white;
    `}

  ${({ isRow }) =>
    isRow &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
`

const Button = styled.button`
  gap: 10px;
  border: none;
  display: flex;
  color: #ff7f00;
  cursor: pointer;
  min-width: 64px;
  background: none;
  margin-top: 10px;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 4px;
  align-items: center;
  font-family: 'Roboto';
  text-transform: uppercase;
  &:hover {
    color: #ffffff;
    background-color: #ff7f00;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`

export {
  ListItemText,
  ListItem,
  ContainerChartBar,
  List,
  Container,
  ContainerCards,
  Grid,
  Button
}
