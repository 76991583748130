import StepFirst from '../Stepper/StepFirst'
import SecondStep from '../Stepper/StepSecond'
import ThirdStep from '../Stepper/StepThird'

const STEPS = [
  {
    label: 'Dados do curso',
    content: ({ formik, setIsPole }) => (
      <StepFirst formik={formik} setIsPole={setIsPole} />
    )
  },
  {
    label: 'Dados do aluno',
    content: ({ formik, setSnack, isValidCpf, setIsValidCpf }) => (
      <SecondStep
        formik={formik}
        setSnack={setSnack}
        isValidCpf={isValidCpf}
        setIsValidCpf={setIsValidCpf}
      />
    )
  },
  {
    label: 'Final',
    content: ({ formik, enrollmentDetails }) => (
      <ThirdStep formik={formik} enrollmentDetails={enrollmentDetails} />
    )
  }
]

export { STEPS }
