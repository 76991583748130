import React, { Suspense } from 'react'
import { ArrowBack } from '@mui/icons-material'
import { LoadingPage, Snackbar } from 'components/molecules'
import { HeaderPage } from 'components/organisms'
import {
  AccordionFilter,
  InputSwitch,
  TextNotFound,
  ToastComponent
} from 'components/atoms'
import { IsEmpty } from 'services/helpers'
import { Grid } from '@mui/material'
import * as Styled from './style'

const TemplateDefaultFormAndResult = ({
  loadingOpen,
  snackProps,
  accordion,
  nameAccordion,
  titleHeader,
  labelButtonHeader,
  onClickButton,
  classNameHeader,
  linkHeader,
  titleAccordion = 'Selecione e preencha os campos para realizar uma busca',
  iconInformative,
  messageInformative,
  formHeader,
  children,
  listItems,
  expanded,
  setExpanded,
  nameToggle,
  isToggle,
  setIsToggle,
  hasIconButton,
  separator,
  breadcrumbsItems
}) => (
  <Suspense fallback={<LoadingPage open />}>
    <Styled.Content>
      <LoadingPage open={loadingOpen} />

      <HeaderPage
        title={titleHeader}
        labelCallToAction={labelButtonHeader}
        classNameCallToAction={classNameHeader}
        linkCallToAction={linkHeader}
        separator={separator}
        callToAction={onClickButton}
        breadcrumbsItems={breadcrumbsItems}
        startIcon={hasIconButton && <ArrowBack />}
        divider
      />
      {nameToggle && (
        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Styled.SwitchAdvSearch>
              <InputSwitch
                label={nameToggle}
                checked={isToggle}
                onClick={() => setIsToggle(!isToggle)}
              />
            </Styled.SwitchAdvSearch>
          </Grid>
        </Grid>
      )}

      {nameAccordion ? (
        <AccordionFilter
          title={titleAccordion}
          expanded={expanded}
          setExpanded={setExpanded}
          nameAccordion={nameAccordion}
          margin
          list={listItems}
        >
          {accordion}
        </AccordionFilter>
      ) : (
        <Styled.BoxForm>{formHeader}</Styled.BoxForm>
      )}

      {listItems && IsEmpty(listItems) && (
        <TextNotFound icon={iconInformative} text={messageInformative} />
      )}

      <div>{children}</div>

      {snackProps && (
        <Snackbar
          message={snackProps?.snack?.message}
          severity={snackProps?.snack?.severity}
          shouldOpen={snackProps?.snackOpen}
        />
      )}

      <ToastComponent />
    </Styled.Content>
  </Suspense>
)

export default TemplateDefaultFormAndResult
