import { FEEDBACK_FINANCIAL } from './financial'
import { FEEDBACK_PROFESSORS } from './humanResource'
import { FEEDBACK_MANAGEMENT } from './management'
import { FEEDBACK_SECRETARY } from './secretary'
import { FEEDBACK_SYSTEM } from './system'
import { FEEDBACK_COMMERCIAL } from './commercial'

export const FINANCIAL_FEEDBACK_SNACK = {
  ...FEEDBACK_FINANCIAL,
  ...FEEDBACK_MANAGEMENT,
  ...FEEDBACK_SECRETARY,
  ...FEEDBACK_SYSTEM,
  ...FEEDBACK_PROFESSORS,
  ...FEEDBACK_COMMERCIAL
}
