import React, { lazy } from 'react'
import { IconButton, Button, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { IsEmpty } from 'services/helpers/ramda'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const Modal = lazy(() => import('components/atoms/Modal'))
const ListActivities = lazy(() => import('./ListActivities'))
const ModalSkeletonActivities = ({
  open,
  onCloseModal,
  listPresential,
  listOnline,
  onlineScore,
  faceToFaceScore,
  faults,
  faultsAll,
  total,
  messageInfo,
  listRecovery,
  listReclassification,
  recoveryScore,
  reclassificationScore,
  isVisibleLink = true,
  amount,
  loadingModal,
  disciplineId,
  disciplineType
}) => {
  const canShowList =
    !IsEmpty(listPresential) ||
    !IsEmpty(listOnline) ||
    !IsEmpty(listRecovery) ||
    !IsEmpty(listReclassification)

  return (
    <Modal open={open} onClose={onCloseModal}>
      <Styled.Header>
        <Styled.DialogTitle>Atividades da disciplina</Styled.DialogTitle>
        <IconButton onClick={onCloseModal}>
          <CloseIcon />
        </IconButton>
      </Styled.Header>
      <Styled.DialogContent>
        {(disciplineType || !canShowList) && (
          <Styled.Informative>
            Você ainda não possui nenhuma nota registrada.
          </Styled.Informative>
        )}

        {loadingModal ? (
          <Styled.Progress>
            <CircularProgress color="primary" />
            <Styled.LoadingTitle>
              Aguarde carregando as informações
            </Styled.LoadingTitle>
          </Styled.Progress>
        ) : (
          <>
            {canShowList && !disciplineType && (
              <>
                <Styled.Info>
                  <Styled.Title>Atividades</Styled.Title>
                  <Styled.Text>Data</Styled.Text>
                  <Styled.Text>Nota</Styled.Text>
                </Styled.Info>

                {listPresential && (
                  <ListActivities
                    listOptions={listPresential}
                    score={faceToFaceScore}
                  />
                )}

                {listOnline && (
                  <ListActivities
                    listOptions={listOnline}
                    score={onlineScore}
                  />
                )}

                {listReclassification && (
                  <ListActivities
                    listOptions={listReclassification}
                    score={reclassificationScore}
                  />
                )}

                {listRecovery && (
                  <ListActivities
                    listOptions={listRecovery}
                    score={recoveryScore}
                  />
                )}

                <Styled.Box>
                  <Styled.Title>Faltas</Styled.Title>
                  <Styled.Title>{`${faults} /${faultsAll}`}</Styled.Title>
                </Styled.Box>

                <Styled.BoxTotal>
                  <Styled.Title>Total</Styled.Title>
                  <Styled.Title>{`${total} /${amount}`}</Styled.Title>
                </Styled.BoxTotal>

                <Styled.Message>{messageInfo}</Styled.Message>
              </>
            )}
          </>
        )}

        <Styled.Footer>
          <Button variant="outlined" onClick={onCloseModal}>
            Fechar
          </Button>
          {isVisibleLink && (
            <Styled.BulletinLink
              className={TAG_MANAGER.btn_student_opens_report_card}
              to={`${ROUTE.STUDENT_MY_SCORES}?disciplineId=${disciplineId}`}
            >
              Ver boletim
            </Styled.BulletinLink>
          )}
        </Styled.Footer>
      </Styled.DialogContent>
    </Modal>
  )
}
export default ModalSkeletonActivities
