const emptyValue = ' '
const companyIdTrails = 85
const trails = 937

const initStateFormSelectDate = {
  startDate: '',
  endDate: ''
}

const initStateFormOcurrence = {
  startDate: '',
  endDate: '',
  typeFile: '',
  category: '',
  type: ''
}

const typeFormat = [
  {
    value: 'excel',
    label: 'Excel'
  },
  {
    value: 'pdf',
    label: 'PDF'
  }
]

export {
  emptyValue,
  initStateFormSelectDate,
  typeFormat,
  initStateFormOcurrence,
  companyIdTrails,
  trails
}
