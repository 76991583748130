import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getListGroups = async () => {
  const response = await httpGet(`${ENDPOINT.LIST_GROUPS}`)

  return response
}

export const getListContratos = async () => {
  const response = await httpGet(`${ENDPOINT.BASE_URL}/direct/contracts`)

  return response
}

export const getValidateCoupon = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.VALIDATE_COUPON}?${convertToParams(params)}`
  )

  return response
}

export const getRegistrationDetails = async (ID) => {
  const response = await httpGet(`${ENDPOINT.BASE_URL}/${ID}`)

  return response
}

export const getDetailCampaign = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.GET_CAMPAIGN}?${convertToParams(params)}`
  )

  return response
}

export const getBoleto = async (ID) => {
  const response = await httpGet(`${ENDPOINT.BASE_URL}/${ID}`)

  return response
}

export const updateStudent = async (params, idRegister, isFinancial) => {
  const response = await httpPut(
    `${ENDPOINT.BASE_URL}/${idRegister}?is_financial=${isFinancial}`,
    {
      ...params
    }
  )
  return response
}

export const registrationDirect = async (params) => {
  const response = await httpPost(`${ENDPOINT.BASE_URL_DIRECT}`, {
    ...params
  })
  return response
}

export const registrationStudent = async (params, financialOfficer) => {
  const response = await httpPost(
    `${ENDPOINT.BASE_URL}?is_financial=${financialOfficer}`,
    {
      ...params
    }
  )
  return response
}

export const registrationFinancialOfficer = async (params, isFinancial, id) => {
  const response = await httpPost(
    `${ENDPOINT.BASE_URL}/${id}?is_financial=${isFinancial}`,
    {
      ...params
    }
  )
  return response
}

export const getValidateAge = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.VALIDATE_AGE}?${convertToParams(params)}`
  )
  return response
}

export const getStudentsCpf = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.GET_STUDENT_CPF}?${convertToParams(params)}`
  )

  return response
}

export const registrationCopy = async (params, id) => {
  const response = await httpPost(`${ENDPOINT.BASE_URL_MANUAL}/${id}/copy`, {
    ...params
  })
  return response
}

export const registrationConfirm = async ({ params, id }) => {
  const response = await httpPost(`${ENDPOINT.BASE_URL_MANUAL}/${id}/confirm`, {
    ...params
  })
  return response
}

export const getStudentsDirectCpf = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.BASE_URL_DIRECT}/verify-cpf?${convertToParams(params)}`
  )

  return response
}

export const getListEnrollmentsCourses = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.LIST_MATRICULATION}?${convertToParams(params)}`
  )

  return response
}

export const getEnrollments = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.BASE_URL_MANUAL}?${convertToParams(params)}`
  )

  return response
}

export const getDetailEnrollments = async (id) => {
  const response = await httpGet(`${ENDPOINT.BASE_URL_MANUAL}/${id}/details`)

  return response
}

export const getDetailCopyEnrollments = async (id) => {
  const response = await httpGet(`${ENDPOINT.BASE_URL_MANUAL}/${id}/copy`)

  return response
}

export const getListPoles = async (unitId) => {
  const response = await httpGet(`/units/poles/${unitId}`)

  return response
}

export const getListUnits = async () => {
  const response = await httpGet(`${ENDPOINT.BASE_URL_DIRECT}/units`)

  return response
}
