import { ROUTE } from 'services/constants'

const parseResponse = (data, isName) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: isName ? name : id, label: name }
    return [...acc, newItem]
  }, [])

const breadcrumbsItems = [
  {
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION,
    label: 'Comercial'
  },
  {
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION_CREATE,
    label: '<b>Pré matrícula</b>'
  },
]

const selectListType = (data) =>
  data?.data?.reduce((acc, cur) => {
    const { grupo } = cur
    const newItem = { value: grupo, label: grupo }

    return [...acc, newItem]
  }, [])

export { breadcrumbsItems, parseResponse, selectListType }
