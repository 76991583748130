import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, IconButton, Typography } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import {
  DropDown,
  IconUploadPhoto,
  ModalSummaryOpenTickets
} from 'components/molecules'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  convertToParams,
  getMenuItems,
  makeURLAccessLegacy,
  updateErrorMessage
} from 'services/helpers'
import { useStore } from 'store/GlobalContext'
import { useActionSelected } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { UploadPhoto } from 'components/organisms'
import {
  sendSms,
  sendStudentEmail,
  uploadStudentPhoto
} from 'services/api/admin'
import isAllowUserAccess from 'routes/helpers/isAllowUserAccess'
import {
  MESSAGE,
  SUCCESS,
  password,
  requeriment,
  tickets
} from '../../constants'
import ModalConfirmResetPassword from '../ModalConfirmResetPassword'
import ModalSms from '../ModalSms'
import ModalEmail from '../ModalEmail'

const Header = ({ studentInfo, setLoading, setSnack }) => {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { onActionSelected, selected, onResetAction } = useActionSelected()

  const [openPhoto, setOpenPhoto] = useState(false)
  const [photo, setPhoto] = useState('')
  const [openModalSms, setOpenModalSms] = useState(false)
  const [openModalEmail, setOpenModalEmail] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePassword = (item) => {
    onActionSelected(password, item)
  }

  const handleTickets = (item) => {
    onActionSelected(tickets, item)
  }

  const handleRequeriment = (item) => {
    onActionSelected(requeriment, item)
    const params = convertToParams({
      register: item.register
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_REQUISITION}?${params}`)
  }

  const handleHistory = (item) => {
    onActionSelected(requeriment, item)
    const params = convertToParams({
      student: item.register,
      name: item.name
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_HISTORY}?${params}`)
  }

  const handleScore = (item) => {
    const params = convertToParams({
      student: item.cpf
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_REQUISITION_CREATE}?${params}`)
  }

  const handleSendSms = () => {
    setOpenModalSms(true)
  }

  const handleSendEmail = () => {
    setOpenModalEmail(true)
  }

  const handleDeclaration = (item) => {
    const params = convertToParams({
      name: item.name,
      register: item.register
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_DECLARATION}?${params}`)
  }

  const handleLinkClassDiscipline = () => {
    navigate(
      `${ROUTE.ADMIN_SECRETARY_STUDENTS_CLASS_DISCIPLINE}?${convertToParams({
        register: studentInfo.student.register,
        studentName: studentInfo.student.name
      })}`
    )
  }

  const handleInteractions = (item) => {
    const params = convertToParams({
      name: item.name,
      register: item.register
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_INTERACTIONS}?${params}`)
  }

  const onSubmitSms = async (values) => {
    const payload = {
      phone: [studentInfo.student?.cellphone.replace(/\D/g, '')],
      content: values?.content,
      register: studentInfo?.student?.register,
      name: studentInfo?.student?.name
    }

    setSnack('')
    setLoading(true)

    const { error, status } = await sendSms(payload)
    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToResetPassword
      })
    }
    setOpenModalSms(false)
    return setSnack(MESSAGE.SUCCESS_SMS, SUCCESS)
  }

  const onSubmitEmail = async (values) => {
    const payload = {
      email: [studentInfo?.student?.email],
      subject: values?.subject,
      html_text: values?.html_text,
      register: studentInfo?.student?.register
    }

    setSnack('')
    setLoading(true)

    const { error, status } = await sendStudentEmail(payload)
    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToResetPassword
      })
    }
    setOpenModalEmail(false)
    return setSnack(MESSAGE.SUCCESS_EMAIL, SUCCESS)
  }

  const paramsGetMenu = {
    item: studentInfo.student,
    handleClose,
    handlePassword,
    handleRequeriment,
    handleTickets,
    handleSendSms,
    handleSendEmail,
    handleHistory,
    handleDeclaration,
    handleInteractions,
    handleLinkClassDiscipline
  }

  const handleUpload = async (file) => {
    setSnack('')
    setLoading(true)
    const { error, status } = await uploadStudentPhoto(
      studentInfo.student.register,
      file
    )
    setLoading(false)
    if (!error) return true

    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUploadStudentPhoto
    })
    return false
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={5}>
        <Box display="flex" alignItems="start">
          <Box>
            <IconUploadPhoto
              avatarAdmin
              onClick={() => setOpenPhoto(!openPhoto)}
              photo={photo || studentInfo.student.academic_profile_image}
            />
            <Typography variant="body2" align="center">
              {studentInfo.student.register}
            </Typography>
          </Box>

          <Box ml={2}>
            <Typography variant="h5" mb={1.1}>
              {studentInfo.student.social_name
                ? `${studentInfo.student.social_name} (NS)`
                : studentInfo.student.name}
            </Typography>
            {studentInfo.student.social_name && (
              <Typography variant="body2" mb={1.1}>
                {studentInfo.student.name}
              </Typography>
            )}
            <Typography variant="body2" mb={1.1}>
              {studentInfo.student.email}
            </Typography>
            {studentInfo.student?.telephone && (
              <Typography variant="body2">
                {studentInfo.student.telephone}
              </Typography>
            )}
            {studentInfo.student?.cellphone && (
              <Typography variant="body2">
                {studentInfo.student.cellphone}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="start">
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
        </Box>
      </Box>

      <UploadPhoto
        open={openPhoto}
        setOpen={setOpenPhoto}
        setPhoto={setPhoto}
        handleUpload={handleUpload}
      />

      <DropDown
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        menuItems={getMenuItems(paramsGetMenu)}
      />

      <ModalSummaryOpenTickets
        open={selected?.type === tickets}
        handleClose={onResetAction}
        register={studentInfo.student.register}
        name={studentInfo.student.name}
        setLoading={setLoading}
        setSnack={setSnack}
      />

      <ModalConfirmResetPassword
        open={selected?.type === password}
        handleClose={onResetAction}
        onClickNotConfirm={onResetAction}
        onClickConfirm={onResetAction}
        student={studentInfo.student}
        setLoading={setLoading}
        setSnack={setSnack}
      />

      <ModalSms
        open={openModalSms}
        handleClose={() => setOpenModalSms(false)}
        onSubmit={onSubmitSms}
        title="Enviar SMS"
        message="Escreva abaixo a mensagem que deseja enviar via SMS ao destinatário:"
        setSnack={setSnack}
        phone={studentInfo.student?.cellphone}
      />

      <ModalEmail
        open={openModalEmail}
        handleClose={() => setOpenModalEmail(false)}
        onSubmit={onSubmitEmail}
        title="Enviar E-mail"
        message="Preencha os campos abaixo para enviar um e-mail ao destinatário:"
        setSnack={setSnack}
        email={studentInfo.student.email}
      />
    </>
  )
}

export default Header
