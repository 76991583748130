import { activeText, inactiveText } from './constants'

const currentDate = new Date()

const disabledLinkedStartDate = (item) => {
  const beginDate = new Date(item.begin_date)

  return beginDate < currentDate
}

const disabledLinkedEndDate = (item) => {
  const endDate = new Date(item.end_date)

  return endDate < currentDate
}

const labelTable = ({ doneText, item }) => {
  if (currentDate > new Date(item.end_date) && item?.active) {
    return doneText
  }
  return item?.active ? activeText : inactiveText
}

const filterFutureDates = (obj) => {
  const currentDates = new Date()

  return Object.keys(obj).reduce((filteredObj, key) => {
    const dateValue = new Date(obj[key])

    return {
      ...filteredObj,
      ...(dateValue > currentDates && { [key]: obj[key] })
    }
  }, {})
}

export {
  disabledLinkedStartDate,
  disabledLinkedEndDate,
  labelTable,
  currentDate,
  filterFutureDates
}
