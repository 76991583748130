import { TextField } from 'components/molecules'

const createTextField = (defaultValue) => (
  <TextField
    isMargin={0}
    id="outlined-size-small"
    defaultValue={defaultValue}
    size="small"
    sx={{
      '& > :not(style)': { m: 0, width: '13ch' }
    }}
  />
)

const disciplinesList = [
  {
    id: 1,
    discipline: 'Gestão de Processos',
    workload: '50h00min',
    module: '1º',
    teacher: 'IVANILDO ARAUJO VIEIRA',
    start: createTextField('26/07/2022'),
    end: createTextField('23/12/2022')
  },
  {
    id: 2,
    discipline: 'Produção e Administração de Materiais',
    workload: '50h00min',
    module: '1º',
    teacher: 'MÁRCIO DE ALVARENGA',
    start: createTextField('26/07/2022'),
    end: createTextField('23/12/2022')
  },
  {
    id: 3,
    discipline: 'Contabilidade e Custos',
    workload: '50h00min',
    module: '1º',
    teacher: 'REINALDO GAMA SOARES',
    start: createTextField('26/07/2022'),
    end: createTextField('23/12/2022')
  }
]

export { disciplinesList }
