import { ROUTE } from 'services/constants'

const MESSAGE = {
  newSchool: 'Nova turma criada com sucesso!'
}

const inactive = 0

const initialState = {
  approval: '6',
  recovery: '0',
  min_workload: '0',
  classDuration: '60',
  absences: '25',
  unity: '',
  pole: '',
  group: '',
  name: '',
  method: '',
  startDate: null,
  endDate: null,
  modality: '',
  level: '',
  course: '',
  curriculum: '',
  disciplines: [],
  module: '',
  shift: '',
  qtyClass: '',
  supervisor: null,
  reEnrollmentDates: []
}

const breadCrumbsFirstStage = [
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
    label: 'Acadêmico'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
    label: 'Turmas'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_CREATE,
    label: 'Nova Turma'
  }
]

const maxLessons = 10

const lessonQtyList = Array.from({ length: maxLessons }, (_, index) => ({
  value: (index + 1).toString(),
  label: (index + 1).toString()
}))

export { breadCrumbsFirstStage, initialState, MESSAGE, inactive, lessonQtyList }
