import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { StepLabel, Grid } from '@mui/material'
import { Button } from 'components/atoms'
import { INITIAL_STEP, MIDDLE_STEP } from './constants'
import * as Styled from './style'

const Stepper = ({
  activeStep,
  setActiveStep,
  steps,
  labelNextStap,
  labelgoBackInitial,
  formik,
  labelFinallyStep,
  labelGoBack,
  stepFinal,
  children,
  disabledNext,
  initialState
}) => {
  const validationSteps = activeStep !== stepFinal

  return (
    <>
      <Styled.Stepper activeStep={activeStep}>
        {steps.map((stap) => (
          <Styled.Step>
            <StepLabel>{stap.label}</StepLabel>
          </Styled.Step>
        ))}
      </Styled.Stepper>

      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={12} md={12}>
          {children}
        </Grid>
        <Styled.Grid item xs={12} sm={12} md={12}>
          {validationSteps ? (
            <>
              {validationSteps && activeStep !== INITIAL_STEP && (
                <Button
                  startIcon={<ArrowBack />}
                  variant="outlined"
                  value={
                    validationSteps && activeStep - MIDDLE_STEP
                      ? labelNextStap
                      : labelGoBack
                  }
                  onClick={() => setActiveStep(activeStep - MIDDLE_STEP)}
                />
              )}
              <Button
                type={
                  validationSteps && activeStep - MIDDLE_STEP
                    ? 'button'
                    : 'submit'
                }
                endIcon={<ArrowForward />}
                value={
                  validationSteps && activeStep - MIDDLE_STEP
                    ? labelNextStap
                    : labelFinallyStep
                }
                onClick={() =>
                  validationSteps &&
                  activeStep - MIDDLE_STEP &&
                  setActiveStep(activeStep + MIDDLE_STEP)
                }
                disabled={disabledNext}
              />
            </>
          ) : (
            <Styled.Box>
              <Button
                value={labelgoBackInitial}
                onClick={() => {
                  if (initialState && formik) formik.resetForm(initialState)
                  setActiveStep(INITIAL_STEP)
                }}
              />
            </Styled.Box>
          )}
        </Styled.Grid>
      </Grid>
    </>
  )
}

export default Stepper
