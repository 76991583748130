import PropTypes from 'prop-types'
import { Select as MuiSelect, MenuItem, InputLabel } from '@mui/material'
import * as Styled from './style'

const Select = ({
  id,
  label,
  value,
  size,
  optionsList,
  helperText,
  isMargin,
  disabled,
  className,
  isColor,
  width,
  error,
  ...props
}) => {
  const currentValue = props.hasDontSensitive ? value?.toUpperCase() : value
  const options = props.hasDontSensitive
    ? optionsList?.map((option) => ({
        ...option,
        value: option.value?.toUpperCase()
      }))
    : optionsList

  return (
    <Styled.Container
      $error={error}
      fullWidth
      $isMargin={isMargin}
      $width={width}
    >
      <InputLabel {...props} id={`input-label-id-${id}`} size={size}>
        {label}
      </InputLabel>
      <MuiSelect
        className={className}
        fullWidth
        label={label}
        labelId="demo-simple-select-label"
        id={`select-id-${id}`}
        value={currentValue}
        size={size}
        disabled={disabled}
        {...props}
      >
        {options?.map((option) => (
          <MenuItem
            value={option.value}
            key={`${option.value}-${option.label}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && (
        <Styled.FormText
          $isColor={isColor}
          dangerouslySetInnerHTML={{ __html: helperText }}
        />
      )}
    </Styled.Container>
  )
}

export default Select

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  error: PropTypes.bool // Propriedade error adicionada
}

Select.defaultProps = {
  size: 'normal',
  error: false // Valor padrão para a propriedade error
}
