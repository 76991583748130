import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE, SUCCESS } from 'services/constants'
import { useMakeService, useSnack } from 'services/hooks'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import { parseUnits, updateErrorMessage } from 'services/helpers'
import { createSchoolClasses } from 'services/api/admin'
import FEEDBACK_SNACK from 'feedBackSnack'
import Tabs from './components/Tabs'
import { FirstStage } from './components/firstStage'
import { breadCrumbsItems, parseSubmitNewCLass } from './helpers'
import * as Styled from './style'
import { MESSAGE, initialState } from './constants'

import { getAllServices, handleGradeSheet, onUnitsRegions } from './services'
import { schema } from './schema'

const CreateByUpdateClass = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [listGradeSheet, setListGradeSheet] = useState([])
  const [listPole, setListPole] = useState([])
  const [hasPole, setHasPole] = useState(false)
  const [confirmCurriculum, setConfirmCurriculum] = useState(false)
  const [formValues, setFormValues] = useState(initialState)
  const [firstStageCompleted, setFirstStageCompleted] = useState(false)
  const [shifts, setShifts] = useState([])
  const [courses, setCourses] = useState([])
  const [modules, setModules] = useState([])
  const [levels, setLevels] = useState([])
  const [listCodinator, setListCodinator] = useState([])
  const [modalities, setModalities] = useState([])
  const [evaluations, setEvaluations] = useState([])
  const [selectedLevel, setSelectedLevel] = useState({
    selectedLevel: false,
    unit_id: null,
    level: ''
  })
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)

  const paramsMakeService = {
    unitsValue: true,
    isUnits: true,
    isGroup: true,
    setSnack
  }

  const { unitys, group } = useMakeService(paramsMakeService)

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS, {
      state: { savedSearch: true }
    })
  }

  const createSchoolClass = async (values) => {
    setSnack('')
    setLoadingVisibility(true)

    const { error, status } = await createSchoolClasses(
      parseSubmitNewCLass({ ...values, unitys: parseUnits(unitys) })
    )
    setLoadingVisibility(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateSchoolClass
      })
    }

    setSnack(MESSAGE.newSchool, SUCCESS)
    setFormValues(initialState)

    return setTimeout(
      () =>
        navigate(ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS, {
          state: { savedSearch: true }
        }),
      3000
    )
  }

  const { values, handleSubmit, setFieldValue, touched, errors, dirty } =
    useFormik({
      initialValues: formValues,
      validateOnMount: true,
      enableReinitialize: true,
      onSubmit: createSchoolClass,
      validationSchema: !hasPole && schema
    })

  useEffect(() => {
    if (formValues.course && formValues?.level)
      handleGradeSheet({ setSnack, formValues, setListGradeSheet })
  }, [formValues.course])

  useEffect(() => {
    if (hasPole)
      onUnitsRegions({ setSnack, setListPole, unitSelected: formValues.unity })
  }, [hasPole])

  useEffect(() => {
    getAllServices({
      setSnack,
      setShifts,
      setLevels,
      setLoadingVisibility,
      setEvaluations,
      setModalities,
      setModules,
      setListCodinator
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems(firstStageCompleted)}
      classNameHeader="goBack"
      onClickButton={() => setOpenModal(true)}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <>
          <Styled.Form onSubmit={handleSubmit} noValidate autoComplete="off">
            {!firstStageCompleted ? (
              <FirstStage
                setFirstStageCompleted={setFirstStageCompleted}
                values={values}
                setFormValues={setFormValues}
                selectedLevel={selectedLevel}
                setSelectedLevel={setSelectedLevel}
                setFieldValue={setFieldValue}
                setHasPole={setHasPole}
                unitys={parseUnits(unitys)}
              />
            ) : (
              <>
                <Tabs
                  loadingVisibility={loadingVisibility}
                  setSnack={setSnack}
                  listCodinator={listCodinator}
                  values={values}
                  openModal={openModal}
                  selectedLevel={selectedLevel}
                  setSelectedLevel={setSelectedLevel}
                  setLoadingVisibility={setLoadingVisibility}
                  levels={levels}
                  groups={group}
                  setConfirmCurriculum={setConfirmCurriculum}
                  setCourses={setCourses}
                  listPole={listPole}
                  hasPole={hasPole}
                  listGradeSheet={listGradeSheet}
                  setFormValues={setFormValues}
                  evaluations={evaluations}
                  modalities={modalities}
                  setHasPole={setHasPole}
                  course={courses}
                  periods={shifts}
                  modules={modules}
                  setFieldValue={setFieldValue}
                  setOpenModal={setOpenModal}
                  unitys={parseUnits(unitys)}
                  touched={touched}
                  errors={errors}
                />
                <Styled.Box>
                  <ButtonFooterV2
                    labelClose="Cancelar"
                    labelConfirm="Salvar"
                    disabled={!dirty}
                    size="medium"
                  />
                </Styled.Box>
              </>
            )}
          </Styled.Form>

          <ModalConfirm
            open={confirmCurriculum}
            handleClose={() => setConfirmCurriculum(false)}
            icon={<Styled.IconWarningAmber color="secondary" />}
            title="Confirmar Matriz Curricular"
            message={`A Matriz Curricular selecionada <b>${values?.curriculum}</b> está correta?`}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => {
              setConfirmCurriculum(false)
              setFieldValue('curriculum', '')
            }}
            onClickConfirm={() => setConfirmCurriculum(false)}
            colorButtonConfirm="error"
          />
        </>
      }
    />
  )
}

export default CreateByUpdateClass
