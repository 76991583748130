/* eslint-disable camelcase */
import { lazy, useState } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { Mail, FileDownload, QueryStats } from '@mui/icons-material'
import { ModalConfirm, PaginationAdvanced } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import { useCheckedTable, useSortTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { isEmpty, paginationFilter } from 'services/helpers'
import { useUserStore } from 'store/GlobalContext'
import { ROUTE } from 'services/constants'
import { headCells } from '../../config'
import { defaultItemsPerPage, defaultPage } from '../../constants'
import { DeleteIcon } from './TableFooter/style'
import {
  companyIdTrails,
  initStateFormOcurrence,
  initStateFormSelectDate,
  trails
} from './constants'
import * as Styled from './style'
import { searchReports } from '../../services'
import ModalOcurrences from './components/ModalOcurrences'

const TableFooter = lazy(() => import('./TableFooter'))
const ModalEmailSending = lazy(() => import('./components/ModalEmailSending'))
const ModalSelectDate = lazy(() => import('./components/ModalSelectDate'))

const Table = ({
  rows,
  unit,
  count,
  unitSelected,
  setFilter,
  filter,
  page,
  situation,
  totalPage,
  setPage,
  deleteSubmitClass,
  formValues,
  setSnack,
  setLoading,
  onSubmit,
  navigate
}) => {
  const { company_id: companyId } = useUserStore()
  const [openModalSubmitJoy, setOpenModalSubmitJoy] = useState(false)
  const [openModalReport, setOpenModalReport] = useState(false)
  const [isReportTrilhas, setIsReportTrilhas] = useState(false)
  const [openModalOcurrences, setOpenModalOcurrences] = useState(false)
  const [openModalEmail, setOpenModalEmail] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)

  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(rows)

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected,
    setSelected
  } = useCheckedTable(rows, 'class_id')

  const handleSetPage = (_, currentPage) => {
    setPage(+currentPage)
  }

  const selectedItems = selected?.length
  const isEmptyTrails =
    companyId === companyIdTrails && formValues.unit === trails
  const isEmptySelected = isEmpty(selected)

  const classesAllowedDelete = sortTable.filter(
    (el) => el.can_delete_school_class
  )

  const disabledDeleteSelectedClasses =
    isEmpty(selected) ||
    selected.some(
      (classId) => !classesAllowedDelete.some((el) => el.class_id === classId)
    )

  const handleCheckAll = (e) => {
    handleSelectAllClick(e)
  }

  const onCheck = (classId) => {
    handleClick(classId)
  }

  const onDelete = (classId) => {
    if (!isSelected(classId)) handleClick(classId)
    setOpenModalConfirm(true)
  }

  return (
    <>
      <Styled.Toolbar>
        <Styled.Button
          variant="outlined"
          onClick={() => setOpenModalConfirm(true)}
          disabled={disabledDeleteSelectedClasses}
          $isDisabled={disabledDeleteSelectedClasses}
          $isColorRed
          startIcon={<DeleteIcon />}
          className={TAG_MANAGER.secretary_bnt_delete_scoolCLass}
        >
          Excluir
        </Styled.Button>
        <Styled.Button
          variant="outlined"
          startIcon={<Mail />}
          onClick={() => setOpenModalEmail(true)}
          disabled={isEmptySelected}
          $isDisabled={isEmptySelected}
          className={TAG_MANAGER.financial_btn_starts_changeFlatValue}
        >
          Enviar e-mail
        </Styled.Button>

        <Styled.Button
          variant="outlined"
          onClick={() => {
            setOpenModalReport(true)
            setIsReportTrilhas(false)
          }}
          disabled={isEmptySelected}
          $isDisabled={isEmptySelected}
          startIcon={<FileDownload />}
          className={TAG_MANAGER.financial_btn_starts_excludesBatchPlan}
        >
          relatório geral
        </Styled.Button>

        {isEmptyTrails && (
          <Styled.Button
            variant="outlined"
            onClick={() => {
              setIsReportTrilhas(true)
              setOpenModalReport(true)
            }}
            disabled={isEmptySelected}
            $isDisabled={isEmptySelected}
            startIcon={<FileDownload />}
            className={TAG_MANAGER.financial_btn_starts_excludesBatchPlan}
          >
            relatório de trilhas
          </Styled.Button>
        )}

        <Styled.Button
          variant="outlined"
          onClick={() => setOpenModalOcurrences(true)}
          disabled={isEmptySelected}
          $isDisabled={isEmptySelected}
          startIcon={<FileDownload />}
        >
          ocorrências
        </Styled.Button>

        <Styled.Button
          variant="outlined"
          startIcon={<QueryStats />}
          disabled={!isEmptySelected || !unit}
          $isDisabled={!isEmptySelected || !unit}
          className={TAG_MANAGER.financial_btn_starts_changeFlatValue}
          onClick={() =>
            navigate(ROUTE.ADMIN_SECRETARY_CLASSES_DASHBOARD, {
              state: { savedSearch: true, unitSelected, situation }
            })
          }
        >
          Dashboard
        </Styled.Button>
      </Styled.Toolbar>

      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              checkbox
              numSelected={selectedItems}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={(e) => handleCheckAll(e)}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
            />
            <TableBody>
              {sortTable?.map((row, index) => {
                const isItemSelected = isSelected(row.class_id)
                const labelId = `enhanced-table-${index}`

                return (
                  <TableFooter
                    key={row.class_id}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    formValues={formValues}
                    row={row}
                    onCheck={onCheck}
                    onDelete={onDelete}
                  />
                )
              })}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={page}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(defaultPage)
              setFilter(e.target.value)
            }}
          />
        )}
      </Styled.Paper>

      <ModalConfirm
        open={openModalConfirm}
        icon={<Styled.IconWarningAmber />}
        title="Excluir"
        message="Você tem certeza que deseja excluir essa(s) turma(s)?"
        textButtonNotConfirm="Não"
        classNameBtnConfirm={TAG_MANAGER.secretary_bnt_delete_scoolCLass}
        textButtonConfirm="Sim"
        onClickNotConfirm={() => {
          setSelected([])
          setOpenModalConfirm(false)
        }}
        handleClose={() => setOpenModalConfirm(false)}
        onClickConfirm={() =>
          deleteSubmitClass({
            setSnack,
            setLoading,
            setOpenModalConfirm,
            selected,
            setSelected,
            onSubmit,
            formValues
          })
        }
      />

      <ModalConfirm
        open={openModalSubmitJoy}
        title="Enviar turmas para Joy"
        message="Você tem certeza que deseja enviar as turmas selecionadas para o Moodle da Joy?"
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        className={TAG_MANAGER.academic_btn_confirm_delete_course}
        onClickNotConfirm={() => setOpenModalSubmitJoy(!openModalSubmitJoy)}
        handleClose={() => setOpenModalSubmitJoy(!openModalSubmitJoy)}
      />

      <ModalSelectDate
        open={openModalReport}
        title={`Relatório ${isReportTrilhas ? 'de Trilhas' : 'Geral'}`}
        message="Escolha o período para o relatório que você deseja gerar:"
        textButtonNotConfirm="Cancelar"
        textButtonConfirm="Enviar"
        classNameBtnConfirm={TAG_MANAGER.secretary_bnt_export_generalReport}
        onSubmit={(values) =>
          searchReports({
            values,
            setSnack,
            selected,
            setLoading,
            setSelected,
            isReportTrilhas,
            setOpenModalReport
          })
        }
        handleClose={() => {
          setIsReportTrilhas(false)
          setOpenModalReport(!openModalReport)
        }}
        initialState={initStateFormSelectDate}
        labelDateStart="De"
        labelDateEnd="Até"
      />

      <ModalOcurrences
        open={openModalOcurrences}
        setSnack={setSnack}
        selected={selected}
        title="Relatório de Ocorrências"
        message="Escolha o período para o relatório que você deseja gerar:"
        textButtonNotConfirm="Cancelar"
        textButtonConfirm="Enviar"
        setSelected={setSelected}
        handleClose={() => setOpenModalOcurrences(!openModalOcurrences)}
        initialState={initStateFormOcurrence}
        labelDateStart="De"
        labelDateEnd="Até"
      />

      <ModalEmailSending
        open={openModalEmail}
        setSnack={setSnack}
        setLoading={setLoading}
        setSelected={setSelected}
        selected={selected}
        handleClose={() => setOpenModalEmail(!openModalEmail)}
      />
    </>
  )
}

export default Table
