import { formatDate, formatDateTimePtBr, getLenght } from 'services/helpers'

/* eslint-disable camelcase */
const parseFormValues = ({ data, supervisor }) => ({
  id: data.id,
  code: data.code,
  pole: data.pole,
  min_workload: data.min_workload,
  observation: data.observation,
  curriculum: data.grade_year,
  classDuration: data.lesson_duration,
  startDate: data.begin_at,
  endDate: data.end_at,
  resolution: data.resolution,
  qtyClass: data.amount_classes,
  supervisor
})

const parseStaticFormValues = ({
  unit,
  pole,
  group,
  modality,
  button_pole,
  level,
  course,
  shift,
  module,
  observation,
  avaliation_method,
  minimum_grade,
  minimum_frequency,
  recovery_min,
  begin_at,
  end_at
}) => ({
  unit,
  pole,
  group,
  buttonPole: button_pole,
  modality,
  level,
  course,
  observation,
  shift,
  module,
  recoveryMin: recovery_min,
  avaliationMethod: avaliation_method,
  minimumGrade: minimum_grade,
  minimumFrequency: minimum_frequency,
  beginAt: begin_at,
  endAt: end_at
})

const parseListDisciplines = (data) =>
  data?.data?.reduce((acc, cur) => {
    const {
      id,
      discipline,
      workload_hours,
      workload_minutes,
      has_grouping,
      module,
      professors,
      begin_at,
      end_at,
      previous_start_date,
      start_date_updated_at,
      start_date_updated_from_user
    } = cur
    const newItem = {
      id,
      discipline,
      professors,
      hasGrouping: has_grouping,
      module: module === 0 ? 'Único' : `${module}º`,
      startDate: begin_at,
      endDate: end_at,
      workload: `${workload_hours}h${workload_minutes || '00'}mm`,
      previousStartDate: previous_start_date
        ? formatDate(previous_start_date)
        : '-',
      startDateUpdatedAt: formatDateTimePtBr(start_date_updated_at),
      startDateUpdatedFromUser: start_date_updated_from_user
    }
    return [...acc, newItem]
  }, [])

const parseUpdateDisciplines = (data) =>
  data?.reduce((acc, cur) => {
    const { id, startDate, endDate } = cur

    const newItem = {
      id,
      begin_at: startDate || '',
      end_at: endDate || ''
    }

    return [...acc, newItem]
  }, [])

const parseListGradeSheets = (dataGradeSheets) =>
  dataGradeSheets?.data?.reduce((acc, cur) => {
    const { name } = cur
    const newItem = { value: name, label: name }
    return [...acc, newItem]
  }, [])

const parseSelectDisciplines = (dataSelectDisciplines) =>
  dataSelectDisciplines?.data?.reduce((acc, cur) => {
    const { discipline, id } = cur
    const newItem = { value: id, label: discipline }
    return [...acc, newItem]
  }, [])

const parseTimeDisciplines = (data) =>
  data?.data?.map((cur) => {
    const {
      id,
      start_at,
      end_at,
      monday,
      tuesday,
      thursday,
      wednesday,
      friday,
      saturday
    } = cur

    return {
      id,
      startTime: start_at,
      endTime: end_at,
      monday,
      tuesday,
      thursday,
      wednesday,
      friday,
      saturday
    }
  }) || []

const parseProfessors = (data) =>
  data?.reduce((acc, cur) => {
    const { usuario, nome, cpf } = cur
    const newItem = { value: usuario, label: `${nome} - (${cpf})` }
    return [...acc, newItem]
  }, [])

const parseCreateProfessors = (data) => {
  if (
    getLenght(data.values.teacher) === 1 &&
    data.values.teacher[0].professor === ''
  )
    return []

  return data?.values.teacher?.reduce(
    (acc, cur) => {
      const { order, professor } = cur
      const newItem = data.isBatchAction
        ? {
            id_disciplines: data.selectedItem,
            id_professor: professor.value,
            position: 1
          }
        : {
            id: professor.value,
            position: order
          }

      return data.isBatchAction ? { ...acc, ...newItem } : [...acc, newItem]
    },
    data.isBatchAction ? {} : []
  )
}

const parseModalProfessors = (data) =>
  data?.reduce((acc, cur) => {
    const { position, name, id } = cur
    const newItem = { professor: { value: id, label: name }, order: position }
    return [...acc, newItem]
  }, [])

const parseNewSchedules = (data, classId) =>
  data?.reduce((acc, cur) => {
    const {
      id,
      startTime,
      endTime,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday
    } = cur
    const newItem = {
      ...(id && { id }),
      class_id: classId,
      start_at: startTime,
      end_at: endTime,
      ...(monday && { monday }),
      ...(tuesday && { tuesday }),
      ...(wednesday && { wednesday }),
      ...(thursday && { thursday }),
      ...(friday && { friday }),
      ...(saturday && { saturday })
    }
    return [...acc, newItem]
  }, [])

export {
  parseFormValues,
  parseStaticFormValues,
  parseModalProfessors,
  parseListDisciplines,
  parseListGradeSheets,
  parseSelectDisciplines,
  parseProfessors,
  parseCreateProfessors,
  parseTimeDisciplines,
  parseNewSchedules,
  parseUpdateDisciplines
}
